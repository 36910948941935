import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetDevicesQuery } from '../../../../../api/deviceApi';
import { PricerPagination } from '../../../../../components/atoms/PricerPagination';
import { useMessageHandler } from '../../../../../context/MessageContext';
export function SelectDeviceList(_a) {
    var filter = _a.filter, selectedDevices = _a.selectedDevices, setSelectedDevices = _a.setSelectedDevices;
    var addApiError = useMessageHandler().addApiError;
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    console.log(selectedDevices);
    var _c = useGetDevicesQuery({ page: page, filter: filter }), devices = _c.data, isError = _c.isError;
    useEffect(function () {
        if (isError) {
            addApiError('Failed to fetch devices');
        }
    }, [isError, addApiError]);
    var handleSelection = function (id) { return function () {
        var selectedIndex = selectedDevices.indexOf(id);
        var newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedDevices, id);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedDevices.slice(1));
        }
        else if (selectedIndex === selectedDevices.length - 1) {
            newSelected = newSelected.concat(selectedDevices.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedDevices.slice(0, selectedIndex), selectedDevices.slice(selectedIndex + 1));
        }
        setSelectedDevices(newSelected);
    }; };
    return (_jsxs(Stack, { direction: "column", spacing: 3, children: [_jsx(Typography, { variant: 'body1', children: "Select devices" }), _jsx(List, { sx: { width: '100%' }, children: devices &&
                    devices.content.map(function (device) { return (_jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { role: undefined, onClick: handleSelection(device.id), dense: true, children: [_jsx(ListItemIcon, { children: _jsx(Checkbox, { edge: "start", checked: selectedDevices.indexOf(device.id) !== -1, tabIndex: -1, disableRipple: true, inputProps: { 'aria-labelledby': device.id } }) }), _jsx(ListItemText, { id: device.id, primary: device.id })] }) }, device.id)); }) }), _jsx(PricerPagination, { items: devices, page: page, setPage: setPage })] }));
}
