var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import React from 'react';
import { deviceApi, useSaveDeviceMutation } from '../../../../../api/deviceApi';
import { PricerButton } from '../../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../../context/MessageContext';
import { useAppDispatch } from '../../../../../store/store';
import { FirmwareSelector } from '../../atoms/FirmwareSelector';
import { SelectDeviceList } from '../atoms/SelectDeviceList';
import { SelectedDeviceList } from '../atoms/SelectedDeviceList';
export function DeployFirmwareDialog(_a) {
    var _this = this;
    var open = _a.open, onCancel = _a.onCancel, product = _a.product, firmware = _a.firmware;
    var addApiError = useMessageHandler().addApiError;
    var dispatch = useAppDispatch();
    var _b = React.useState(0), step = _b[0], setStep = _b[1];
    var _c = React.useState(undefined), firmwareId = _c[0], setFirmwareId = _c[1];
    var _d = React.useState(undefined), tag = _d[0], setTag = _d[1];
    var _e = React.useState('version'), type = _e[0], setType = _e[1];
    var _f = React.useState([]), selectedDevices = _f[0], setSelectedDevices = _f[1];
    var saveItem = useSaveDeviceMutation()[0];
    var onNext = function () { return __awaiter(_this, void 0, void 0, function () {
        var _i, selectedDevices_1, device, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(step < 3)) return [3 /*break*/, 1];
                    setStep(function (step) { return step + 1; });
                    return [3 /*break*/, 8];
                case 1:
                    _i = 0, selectedDevices_1 = selectedDevices;
                    _a.label = 2;
                case 2:
                    if (!(_i < selectedDevices_1.length)) return [3 /*break*/, 7];
                    device = selectedDevices_1[_i];
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, saveItem({ id: device, pendingFirmware: firmware.id }).unwrap()];
                case 4:
                    _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    addApiError("Failed to save ".concat(firmware.id), e_1);
                    return [3 /*break*/, 6];
                case 6:
                    _i++;
                    return [3 /*break*/, 2];
                case 7:
                    dispatch(deviceApi.util.invalidateTags(['Devices', 'Device']));
                    onDoCancel();
                    _a.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var onBack = function () {
        if (step < 1 || step > 3) {
            setStep(0);
            return;
        }
        setStep(function (step) { return step - 1; });
    };
    var onDoCancel = function () {
        setType('version');
        setTag(undefined);
        setFirmwareId(undefined);
        setSelectedDevices([]);
        setStep(0);
        onCancel();
    };
    var filter = type === 'version'
        ? "firmware:".concat(firmwareId)
        : type === 'tag'
            ? "tag:".concat(tag)
            : '';
    return (_jsxs(Dialog, { open: open, fullWidth: true, children: [_jsx(DialogTitle, { children: "Deploy version" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: _jsxs(Stack, { direction: "column", spacing: 3, children: [_jsxs(Stepper, { activeStep: step, children: [_jsx(Step, { children: _jsx(StepLabel, { children: "Type of deployment" }) }, "type"), _jsx(Step, { children: _jsx(StepLabel, { children: "Select target" }) }, "target"), _jsx(Step, { children: _jsx(StepLabel, { children: "Select devices" }) }, "devices"), _jsx(Step, { children: _jsx(StepLabel, { children: "Confirm" }) }, "approve")] }), step === 0 && (_jsxs(FormControl, { children: [_jsx(FormLabel, { id: "type", children: "Type of deployment" }), _jsxs(RadioGroup, { "aria-labelledby": "type", value: type, name: "type", onChange: function (e) {
                                            return setType(e.target.value);
                                        }, children: [_jsx(FormControlLabel, { value: "version", control: _jsx(Radio, {}), label: "Select devices by version" }), _jsx(FormControlLabel, { value: "tag", control: _jsx(Radio, {}), label: "Select devices by tags" }), _jsx(FormControlLabel, { value: "manual", control: _jsx(Radio, {}), label: "Select devices manually" })] })] })), step === 1 && type === 'version' && (_jsx(FirmwareSelector, { product: product, firmwareId: firmwareId, setFirmware: function (id) { return setFirmwareId(id); } })), step === 1 && type === 'tag' && (_jsx(TextField, { label: "Tag", value: tag, onChange: function (e) { return setTag(e.target.value); } })), step === 1 && type === 'manual' && (_jsx(Typography, { variant: 'body1', children: "Select devices manually" })), step === 2 && (_jsx(SelectDeviceList, { filter: filter, selectedDevices: selectedDevices, setSelectedDevices: setSelectedDevices })), step === 3 && _jsx(SelectedDeviceList, { devices: selectedDevices })] }) }) }), _jsxs(DialogActions, { children: [step === 0 && (_jsx(PricerButton, { onClick: function () { return onDoCancel(); }, children: "Cancel" })), step !== 0 && (_jsx(PricerButton, { disabled: step < 1, onClick: function () { return onBack(); }, children: "Back" })), _jsxs(PricerButton, { disabled: step > 3, type: "ok", onClick: function () { return onNext(); }, children: [step < 3 && _jsx(_Fragment, { children: "Next" }), step === 3 && _jsx(_Fragment, { children: "Finish" })] })] })] }));
}
