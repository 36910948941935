var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './helpers';
export var emptyFirmware = {
    product: '',
    majorVersion: 0,
    minorVersion: 0,
    gitSha: '',
    changeLog: ''
};
export var firmwareApi = createApi({
    reducerPath: 'firmwareApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: ['Firmwares', 'Firmware'],
    endpoints: function (builder) { return ({
        getFirmwares: builder.query({
            query: function (_a) {
                var product = _a.product, page = _a.page;
                return ({
                    url: "infraconfig/api/v1/firmwares?product=".concat(product, "&page=").concat(page),
                    responseHandler: responseHandler
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: ['Firmwares']
        }),
        getFirmware: builder.query({
            query: function (id) { return ({
                url: "infraconfig/api/v1/firmwares/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Firmware', id: id }]; }
        }),
        addFirmware: builder.mutation({
            query: function (item) { return ({
                url: "infraconfig/api/v1/firmwares",
                method: 'POST',
                body: __assign({}, item),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result) { return [
                { type: 'Firmware', id: (result === null || result === void 0 ? void 0 : result.id) || '' },
                { type: 'Firmwares' }
            ]; }
        }),
        deleteFirmware: builder.mutation({
            query: function (id) { return ({
                url: "infraconfig/api/v1/firmwares/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return [
                { type: 'Firmware', id: id },
                { type: 'Firmwares' }
            ]; }
        }),
        uploadFirmware: builder.mutation({
            query: function (_a) {
                var id = _a.id, filename = _a.filename, firmware = _a.firmware;
                return ({
                    url: "infraconfig/api/v1/firmwares/".concat(id, "?filename=").concat(filename),
                    responseHandler: responseHandler,
                    method: 'POST',
                    headers: {
                        'content-type': 'application/octet-stream'
                    },
                    body: firmware
                });
            },
            invalidatesTags: function (result) { return [{ type: 'Firmware', id: (result === null || result === void 0 ? void 0 : result.id) || '' }]; }
        })
    }); }
});
export var useGetFirmwareQuery = firmwareApi.useGetFirmwareQuery, useGetFirmwaresQuery = firmwareApi.useGetFirmwaresQuery, useAddFirmwareMutation = firmwareApi.useAddFirmwareMutation, useDeleteFirmwareMutation = firmwareApi.useDeleteFirmwareMutation, useUploadFirmwareMutation = firmwareApi.useUploadFirmwareMutation;
