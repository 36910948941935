var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import { labelApi } from '../api/labelApi';
import { useAppDispatch } from '../store/store';
import { useMessageHandler } from './MessageContext';
var PendingContext = React.createContext({});
export var usePendingContext = function () {
    return useContext(PendingContext);
};
export var PendingContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), messages = _b[0], setMessages = _b[1];
    var _c = useState(0), highWatermark = _c[0], setHighWatermark = _c[1];
    var _d = useState({}), subscriptions = _d[0], setSubscriptions = _d[1];
    var stompClient = useStompClient();
    var dispatch = useAppDispatch();
    var addError = useMessageHandler().addError;
    useEffect(function () {
        if (messages.length === 0) {
            setHighWatermark(0);
        }
        else {
            setHighWatermark(function (current) { return Math.max(current, messages.length); });
        }
    }, [messages]);
    var add = useCallback(function (requestId, type, barcode) {
        setMessages(function (current) { return __spreadArray(__spreadArray([], current, true), [{ requestId: requestId, type: type, barcode: barcode }], false); });
        if (stompClient == null) {
            console.log('Not connected, adding offline message', stompClient);
            // TODO: Add error message
            return;
        }
        stompClient.publish({
            destination: '/app/pending',
            body: JSON.stringify({ requestId: requestId })
        });
    }, [stompClient]);
    var remove = useCallback(function (requestId) {
        var remaining = messages.filter(function (m) { return m.requestId !== requestId; });
        if (remaining.length === 0) {
            setHighWatermark(0);
        }
        setMessages(function (current) {
            return current.filter(function (m) { return m.requestId !== requestId; });
        });
    }, [messages]);
    var clear = useCallback(function () {
        setMessages([]);
        setHighWatermark(0);
    }, []);
    var has = useCallback(function () { return messages.length > 0; }, [messages]);
    var get = useCallback(function () { return messages; }, [messages]);
    var subscribe = useCallback(function (requestId, handler) {
        setSubscriptions(function (subs) {
            subs[requestId] = handler;
            return subs;
        });
    }, []);
    var value = useMemo(function () { return ({
        add: add,
        has: has,
        get: get,
        subscribe: subscribe,
        clear: clear,
        percentage: highWatermark > 0
            ? Math.round(((highWatermark - messages.length) / highWatermark) * 1000) / 10
            : 0
    }); }, [add, has, get, subscribe, clear, highWatermark, messages]);
    var onMessage = useCallback(function (message) {
        var msg = JSON.parse(message.body);
        remove(msg.requestId);
        var handler = subscriptions[msg.requestId];
        if (handler !== undefined) {
            handler(msg);
            setSubscriptions(function (subs) {
                delete subs[msg.requestId];
                return subs;
            });
        }
        if (!msg.ack.ack) {
            addError("Failed to ".concat(msg.action, " ").concat(msg.barcode, ": ").concat(msg.reason));
        }
        dispatch(labelApi.util.invalidateTags(['Labels', 'Label']));
    }, [remove, dispatch, subscriptions, addError]);
    useSubscription('/user/queue/pending', onMessage);
    return (_jsx(PendingContext.Provider, { value: value, children: children }));
};
