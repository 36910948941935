var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Box, Checkbox, Paper, Slider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { fromByteArray } from 'base64-js';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useCommandHelpQuery } from '../../../../../../api/api';
import { useSendRawMutation } from '../../../../../../api/labelApi';
import { PricerButton } from '../../../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../../../context/MessageContext';
import { usePendingContext } from '../../../../../../context/PendingContext';
import { useLabel } from '../../../views/ManageLabelView';
import { TextView } from '../../atoms/TextView';
import { ByteTable } from '../atoms/ByteTable';
import { CommandSelector } from '../atoms/CommandSelector';
import { ExplainHex } from '../atoms/ExplainHex';
import { ExplainWord } from '../atoms/ExplainWord';
import { HexByte } from '../atoms/HexByte';
import { HexEditor } from '../atoms/HexEditor';
var convertCommands = function (input) {
    var commands = {};
    input.forEach(function (c) {
        commands[c.id] = c;
    });
    return commands;
};
var ackStrengths = [];
for (var i = 0; i < 15; i++) {
    ackStrengths.push({ value: i, label: i.toString() });
}
export function LabelRawView() {
    var _this = this;
    var _a, _b, _c, _d;
    var _e = useLabel(), label = _e.label, target = _e.target;
    var navigator = useNavigate();
    var addError = useMessageHandler().addError;
    var _f = React.useState([]), selection = _f[0], setSelection = _f[1];
    var _g = React.useState([]), data = _g[0], setData = _g[1];
    var _h = React.useState(0), commandId = _h[0], setCommandId = _h[1];
    var _j = React.useState(0), dmCommandId = _j[0], setDmCommandId = _j[1];
    var _k = React.useState(0), len = _k[0], setLen = _k[1];
    var _l = React.useState(0), acc1 = _l[0], setAcc1 = _l[1];
    var _m = React.useState(0), acc2 = _m[0], setAcc2 = _m[1];
    var _o = React.useState(false), hasReply = _o[0], setHasReply = _o[1];
    var _p = React.useState(false), ack = _p[0], setAck = _p[1];
    var _q = React.useState(0), strength = _q[0], setStrength = _q[1];
    var _r = React.useState({}), commands = _r[0], setCommands = _r[1];
    var _s = React.useState({}), dmCommands = _s[0], setDmCommands = _s[1];
    var sendRaw = useSendRawMutation()[0];
    var _t = usePendingContext(), add = _t.add, subscribe = _t.subscribe;
    var rawCommands = useCommandHelpQuery('commands').data;
    var rawDmCommands = useCommandHelpQuery('dm').data;
    useEffect(function () {
        if (rawCommands) {
            setCommands(convertCommands(rawCommands));
        }
    }, [rawCommands]);
    useEffect(function () {
        if (rawDmCommands) {
            setDmCommands(convertCommands(rawDmCommands));
        }
    }, [rawDmCommands]);
    var command = commandId === 0x34 && dmCommands[dmCommandId]
        ? dmCommands[dmCommandId]
        : commands[commandId];
    var noAcc = ((_a = command === null || command === void 0 ? void 0 : command.acc) === null || _a === void 0 ? void 0 : _a.disabled) || false;
    var hasDm = (command === null || command === void 0 ? void 0 : command.dmCmd) || false;
    var extraData = 2 + (hasDm ? 1 : 0) + (noAcc ? 0 : 2);
    var tmp = new Uint8Array(data.length + extraData);
    // TODO: Remove dm command here
    var i = 0;
    tmp[i++] = commandId;
    tmp[i++] = len;
    if (!noAcc) {
        tmp[i++] = acc1;
        tmp[i++] = acc2;
    }
    if (hasDm) {
        tmp[i++] = dmCommandId;
    }
    data.forEach(function (value, index) { return (tmp[index + extraData] = value); });
    var payload = {
        barcode: label.barcode,
        data: fromByteArray(tmp),
        target: target
    };
    var onSendRaw = function () { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setHasReply(false);
                    return [4 /*yield*/, sendRaw(payload).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'SEND-RAW', label.barcode);
                    subscribe(requestId, function (message) {
                        setHasReply(true);
                        setAck(message.ack.ack);
                        setStrength(message.ack.strength);
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addError('Failed to flash label');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onselectionchange = function (numbers) {
        setSelection(numbers);
    };
    return (_jsx(Paper, { sx: { p: 3 }, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { size: 4, children: _jsx(CommandSelector, { title: "Command", commands: commands, command: commandId, onChange: setCommandId }) }), _jsx(Grid, { size: 2, children: _jsx(HexByte, { title: ((_b = command === null || command === void 0 ? void 0 : command.len) === null || _b === void 0 ? void 0 : _b.title) || 'LEN', value: len, onChange: setLen }) }), _jsx(Grid, { size: 1, children: _jsx(HexByte, { title: ((_c = command === null || command === void 0 ? void 0 : command.acc) === null || _c === void 0 ? void 0 : _c.title) || 'Acc/KeyCode 1', value: acc1, onChange: setAcc1, disabled: noAcc }) }), _jsx(Grid, { size: 1, children: _jsx(HexByte, { title: ((_d = command === null || command === void 0 ? void 0 : command.acc) === null || _d === void 0 ? void 0 : _d.title) || 'Acc/KeyCode 2', value: acc2, onChange: setAcc2, disabled: noAcc }) }), _jsx(Grid, { size: 4, children: _jsx(CommandSelector, { disabled: !hasDm, title: "Command", commands: dmCommands, command: dmCommandId, onChange: setDmCommandId }) }), command !== undefined && (_jsxs(_Fragment, { children: [_jsx(Grid, { size: 12, children: _jsx(Alert, { severity: "info", children: command.description }) }), _jsx(Grid, { size: 12, children: _jsxs(Typography, { variant: "body1", children: ["Maximum payload length is: ", command.length] }) }), _jsx(Grid, { size: 12, children: _jsx(ByteTable, { fields: command.fields, values: data }) })] })), _jsx(Grid, { size: 8, children: _jsx(HexEditor, { data: data, onChange: function (data) { return setData(data); }, selection: selection, onSelectionChange: onselectionchange }) }), _jsx(Grid, { size: 4, children: _jsx(TextView, { value: data }) }), selection.map(function (val, idx) { return (_jsx(Grid, { size: 2, children: _jsx(ExplainHex, { value: val, onChange: function (hex) {
                            return setSelection(selection.map(function (val, i) { return (idx === i ? hex : val); }));
                        } }) }, "eh_".concat(idx))); }), selection.length > 0 && selection.length < 4 && (_jsx(Grid, { size: (4 - selection.length) * 2 })), selection.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Grid, { size: 2, children: _jsx(ExplainWord, { value: selection, endianness: "big" }) }), _jsx(Grid, { size: 2, children: _jsx(ExplainWord, { value: selection, endianness: "little" }) })] })), hasReply && (_jsxs(_Fragment, { children: [_jsx(Grid, { size: 2, children: _jsx(Checkbox, { checked: ack }) }), _jsx(Grid, { size: 10, children: _jsx(Slider, { value: strength, min: 0, max: 15, disabled: true, marks: ackStrengths, valueLabelDisplay: "on" }) })] })), _jsx(Grid, { size: 12, sx: { textAlign: 'end' }, children: _jsx(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, children: _jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(PricerButton, { onClick: function () { return navigator("/labels/".concat(label.barcode)); }, children: "Cancel" }), _jsx(PricerButton, { type: 'ok', onClick: onSendRaw, children: "Send" })] }) }) })] }) }));
}
