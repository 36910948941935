var _a;
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { accessPointApi } from '../api/accessPointApi';
import { transmissionApi } from '../api/api';
import { deviceApi } from '../api/deviceApi';
import { deviceHistoryApi } from '../api/deviceHistoryApi';
import { firmwareApi } from '../api/firmwareApi';
import { itemApi } from '../api/itemApi';
import { labelApi } from '../api/labelApi';
import { profileApi } from '../api/profileApi';
import { untrustedDeviceApi } from '../api/untrustedDeviceApi';
import { authSlice } from '../common/authSlice';
export var store = configureStore({
    reducer: (_a = {},
        _a[authSlice.name] = authSlice.reducer,
        _a[transmissionApi.reducerPath] = transmissionApi.reducer,
        _a[profileApi.reducerPath] = profileApi.reducer,
        _a[labelApi.reducerPath] = labelApi.reducer,
        _a[itemApi.reducerPath] = itemApi.reducer,
        _a[deviceApi.reducerPath] = deviceApi.reducer,
        _a[untrustedDeviceApi.reducerPath] = untrustedDeviceApi.reducer,
        _a[deviceHistoryApi.reducerPath] = deviceHistoryApi.reducer,
        _a[firmwareApi.reducerPath] = firmwareApi.reducer,
        _a[accessPointApi.reducerPath] = accessPointApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware()
            .concat(transmissionApi.middleware)
            .concat(profileApi.middleware)
            .concat(labelApi.middleware)
            .concat(itemApi.middleware)
            .concat(deviceApi.middleware)
            .concat(untrustedDeviceApi.middleware)
            .concat(deviceHistoryApi.middleware)
            .concat(firmwareApi.middleware)
            .concat(accessPointApi.middleware);
    }
});
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
