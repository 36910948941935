import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetIRZonesQuery } from '../../api/api';
import { useMessageHandler } from '../../context/MessageContext';
import { SelectBox } from './form/SelectBox';
export function ZoneList(_a) {
    var zoneId = _a.zoneId, onSetZoneId = _a.onSetZoneId;
    var addError = useMessageHandler().addError;
    var _b = useGetIRZonesQuery(), irZones = _b.data, failedToFetchZones = _b.isError;
    useEffect(function () {
        if (failedToFetchZones) {
            addError('Failed to fetch ir zones');
        }
    }, [failedToFetchZones, addError]);
    return (_jsx(_Fragment, { children: irZones && (_jsx(SelectBox, { title: "Zone", value: zoneId, setValue: onSetZoneId, children: irZones === null || irZones === void 0 ? void 0 : irZones.map(function (zone) { return (_jsx(MenuItem, { value: zone.id, children: zone.title }, zone.id)); }) })) }));
}
