import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ImageIcon from '@mui/icons-material/Image';
import PinIcon from '@mui/icons-material/Pin';
import RadioIcon from '@mui/icons-material/Radio';
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { labelApi, useListLabelsQuery } from '../../../../api/labelApi';
import { buildPageFilterQuery } from '../../../../common/helpers';
import { useQuery } from '../../../../common/hooks/query';
import { PopupMenu } from '../../../../components/atoms/toolbar/PopupMenu';
import { PopupMenuItem } from '../../../../components/atoms/toolbar/PopupMenuItem';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useZoneContext } from '../../../../context/ZoneContext';
import { useAppDispatch } from '../../../../store/store';
import { GridSizeSelector } from '../ManageLabelView/atoms/GridSizeSelector';
import { LabelList } from '../molecules/LabelList';
var SIZES = ['12', '24', '48', '96', '128'];
export function LabelListView() {
    var navigator = useNavigate();
    var addError = useMessageHandler().addError;
    var zoneId = useZoneContext().zoneId;
    var dispatch = useAppDispatch();
    var query = useQuery();
    var page = parseInt(query.get('page') || '0');
    var filter = query.get('filter') || '';
    var _a = useLocalStorage('labels.grid.size', 12), size = _a[0], setSize = _a[1];
    var onSetFilter = function (filter) {
        if (filter === '') {
            navigator("/labels/".concat(buildPageFilterQuery(0, filter)));
        }
        else {
            navigator("/labels/".concat(buildPageFilterQuery(0, filter)));
        }
    };
    var onSetPage = function (page) {
        navigator("/labels/".concat(buildPageFilterQuery(page, filter)));
    };
    var gotoLabel = function (type, id) {
        if (type === 'group') {
            navigator("/labels/".concat(id, "/group").concat(buildPageFilterQuery(page, filter)));
        }
        else {
            navigator("/labels/".concat(id).concat(buildPageFilterQuery(page, filter)));
        }
    };
    var _b = useListLabelsQuery({ page: page, filter: filter, size: size, zoneId: zoneId }), labels = _b.data, isFetching = _b.isFetching, isError = _b.isError, error = _b.error;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch labels: Unknown error');
        }
    }, [isError, addError, error]);
    var refreshApi = function () {
        dispatch(labelApi.util.invalidateTags(['Labels', 'Label']));
    };
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch labels", hasPaper: false, toolbar: _jsxs(_Fragment, { children: [_jsx(TextField, { label: "Filter", fullWidth: true, sx: { maxWidth: 120 }, variant: "outlined", size: "small", value: filter, onChange: function (e) { return onSetFilter(e.target.value); } }), _jsxs(ToggleButtonGroup, { value: filter, exclusive: true, sx: { display: { xs: 'none', sm: 'block' } }, onChange: function (e, v) { return onSetFilter(v); }, "aria-label": "filter", children: [_jsx(ToggleButton, { value: "", "aria-label": "left aligned", children: _jsx(FilterAltOffIcon, {}) }), _jsx(ToggleButton, { value: "type:segment", "aria-label": "centered", children: _jsx(PinIcon, {}) }), _jsx(ToggleButton, { value: "type:graphical", "aria-label": "right aligned", children: _jsx(ImageIcon, {}) }), _jsx(ToggleButton, { value: "type:1650", "aria-label": "justified", children: _jsx(RadioIcon, {}) })] }), _jsx(GridSizeSelector, { value: '' + size, onChange: function (v) { return setSize(+v); }, options: SIZES }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching, sx: { display: { xs: 'none', sm: 'none', md: 'flex' } } }), _jsxs(PopupMenu, { icon: _jsx(AddIcon, {}), children: [_jsx(PopupMenuItem, { path: "/labels/add-label", children: "Label" }), _jsx(PopupMenuItem, { path: "/labels/add-group", children: "Group" })] })] }), children: labels && (_jsx(LabelList, { labels: labels, page: page, setPage: onSetPage, onClick: function (label) { return gotoLabel(label.type, label.barcode); } })) }));
}
