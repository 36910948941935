import { intlFormatDistance } from 'date-fns';
import { formatDate } from 'date-fns/format';
import { useEffect, useRef } from 'react';
export var useInterval = function (callback, delay) {
    var savedCallback = useRef();
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
};
export var hasUndefinedProperties = function (obj) {
    return Object.values(obj).some(function (value) { return typeof value === 'string' && value.trim() === ''; });
};
export var has = function (value) {
    return value != null && value.length > 0;
};
export var stringToColor = function (string) {
    if (!string)
        string = 'aaa';
    var hash = 0;
    var i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (i = 0; i < 3; i += 1) {
        var value = (hash >> (i * 8)) & 0xff;
        color += "00".concat(value.toString(16)).slice(-2);
    }
    return color;
};
export var buildPageFilterQuery = function (page, filter) {
    if (page === 0 && filter === '') {
        return '';
    }
    if (page === 0) {
        return "?filter=".concat(filter);
    }
    if (filter === '') {
        return "?page=".concat(page);
    }
    return "?page=".concat(page, "&filter=").concat(filter);
};
export var relativeTime = function (date1, date2) {
    if (date1 === undefined) {
        return 'Never';
    }
    return intlFormatDistance(new Date(date1), date2 ? new Date(date2) : new Date());
};
export var relativeSeconds = function (date1, date2) {
    if (date1 === undefined || date2 === undefined) {
        return '?';
    }
    var ms = new Date(date1).getTime() - new Date(date2).getTime();
    return ms > 10000 ? Math.round(ms / 1000) : Math.round(ms / 10) / 100;
};
export var toEuropeDate = function (date) {
    if (date === undefined) {
        return '';
    }
    return formatDate(new Date(date), 'yyyy-MM-dd HH:mm:ss');
};
export var toIsoDate = function (date) {
    if (date === undefined) {
        return '';
    }
    return new Date(date).toISOString();
};
export var getHumanFriendlySize = function (size) {
    if (size < 1024) {
        return "".concat(size, " B");
    }
    size /= 1024;
    if (size < 1024) {
        return "".concat(size.toFixed(2), " KB");
    }
    size /= 1024;
    if (size < 1024) {
        return "".concat(size.toFixed(2), " MB");
    }
    size /= 1024;
    return "".concat(size.toFixed(2), " GB");
};
