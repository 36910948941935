import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { usePendingContext } from '../../../../context/PendingContext';
export function PendingMessages() {
    var _a = usePendingContext(), get = _a.get, has = _a.has, clear = _a.clear, percentage = _a.percentage;
    var messages = get();
    var count = messages.length;
    var plural = count > 1 ? 's' : '';
    var tooltip = "".concat(count, " pending message").concat(plural);
    var onClear = function () {
        clear();
    };
    return (_jsx(_Fragment, { children: has() && (_jsxs(Stack, { direction: "row", spacing: 2, alignItems: "center", children: [_jsxs(Box, { sx: { position: 'relative', display: 'inline-flex' }, children: [_jsx(CircularProgress, { variant: "determinate", size: 30, value: percentage }), _jsx(Box, { sx: {
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }, children: _jsx(Tooltip, { sx: { display: { xs: 'none', sm: 'block' } }, title: tooltip, children: _jsx(Typography, { variant: "caption", component: "div", sx: { color: 'text.secondary' }, children: "".concat(Math.round(percentage), "%") }) }) })] }), _jsx(Button, { variant: "outlined", startIcon: _jsx(ClearAllIcon, {}), onClick: onClear, children: "Clear" })] })) }));
}
