import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import { Alert, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { DeleteButton } from '../../../../../../components/atoms/toolbar/DeleteButton';
import { Spacing } from '../../../../../../components/atoms/toolbar/Spacing';
export function ChunkEditor(_a) {
    var index = _a.index, lastIndex = _a.lastIndex, steps = _a.steps, conditional = _a.conditional, onMoveUp = _a.onMoveUp, onMoveDown = _a.onMoveDown, name = _a.name, onSaveName = _a.onSaveName, onRemove = _a.onRemove, onChangeSteps = _a.onChangeSteps, onConditionalChange = _a.onConditionalChange;
    var _b = React.useState(name), newName = _b[0], setNewName = _b[1];
    var _c = React.useState(), helpText = _c[0], setHelpText = _c[1];
    var _d = React.useState('info'), helpSeverity = _d[0], setHelpSeverity = _d[1];
    var inputRef = useRef();
    useEffect(function () {
        setNewName(name);
    }, [name]);
    var updateHelp = function () {
        var elem = inputRef.current;
        var start = elem.selectionStart;
        var end = elem.selectionStart;
        while (start > 0 && elem.textContent[start - 1] !== '\n') {
            start--;
        }
        while (end < elem.textContent.length && elem.textContent[end] !== '\n') {
            end++;
        }
        var commandLine = elem.textContent.substring(start, end);
        var command = commandLine.split(' ', 1);
        var args = commandLine.split(' ').slice(1);
        var severity = 'info';
        var commandHelp;
        if (command == 'send') {
            commandHelp = 'Send command and data to display';
            severity = 'info';
        }
        else if (command == 'delay') {
            if (args.length == 0 || args[0] == '') {
                commandHelp = 'Delay needs a time in milliseconds';
                severity = 'warning';
            }
            else if (args.length > 1) {
                commandHelp = 'Delay only has 1 argument';
                severity = 'error';
            }
            else {
                var time = void 0;
                if (args[0].endsWith('ms')) {
                    time = args[0].substring(0, args[0].length - 2);
                }
                else {
                    time = args[0];
                }
                if (isNaN(time)) {
                    commandHelp = 'Wait needs a time in milliseconds';
                    severity = 'error';
                }
                else if (time < 0) {
                    commandHelp = 'Time cannot be negative';
                    severity = 'error';
                }
                else if (time > 255) {
                    commandHelp = 'Time cannot be more than 255 milliseconds';
                    severity = 'error';
                }
                else {
                    commandHelp = "Wait for ".concat(time, " milliseconds");
                }
            }
        }
        else if (command == 'wait') {
            commandHelp = 'Wait for a condition to be met';
        }
        else if (command == 'reset') {
            commandHelp = 'Reset something';
        }
        else if (command == '') {
            commandHelp = "Available commands are: 'send', 'delay', 'wait'";
        }
        else {
            commandHelp = "Unknown command ".concat(command);
            severity = 'error';
        }
        setHelpSeverity(severity);
        setHelpText("".concat(commandHelp));
    };
    var onClick = function () {
        updateHelp();
    };
    var onKeyUp = function () {
        updateHelp();
    };
    var onBlur = function () {
        setHelpText(undefined);
    };
    var addConditional = function () {
        onConditionalChange(index, 'temperature == 0');
    };
    var onConditionalClick = function () {
        setHelpText('Available conditional keywords are: border_color, temperature and custom_sequence');
    };
    return (_jsx(_Fragment, { children: _jsxs(Stack, { direction: "column", spacing: 3, children: [_jsxs(Stack, { direction: 'row', spacing: 1, children: [_jsx(TextField, { label: "Name", fullWidth: true, sx: { maxWidth: 200 }, variant: "outlined", size: "small", value: newName, onChange: function (e) { return setNewName(e.target.value); }, onBlur: function () { return onSaveName(index, newName); } }), conditional && (_jsxs(FormControl, { variant: "outlined", fullWidth: true, size: "small", sx: { maxWidth: 280 }, children: [_jsx(InputLabel, { htmlFor: "condition", children: "Condition" }), _jsx(OutlinedInput, { spellCheck: false, sx: { fontFamily: 'monospace' }, id: "condition", label: "Condition", value: conditional, onChange: function (e) { return onConditionalChange(index, e.target.value); }, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return onConditionalChange(index, ''); }, edge: "end", children: _jsx(ClearIcon, {}) }) }), onClick: onConditionalClick, onBlur: onBlur })] })), !conditional && (_jsx(Button, { variant: "outlined", onClick: addConditional, startIcon: _jsx(AltRouteIcon, {}), children: "Add conditional" })), _jsx(Spacing, {}), index > 0 && (_jsx(IconButton, { onClick: function () { return onMoveUp(index); }, children: _jsx(ArrowUpwardIcon, {}) })), index <= lastIndex - 2 && (_jsx(IconButton, { onClick: function () { return onMoveDown(index); }, children: _jsx(ArrowDownwardIcon, {}) })), _jsx(DeleteButton, { onClick: function () { return onRemove(index); } })] }), _jsx(TextField, { slotProps: {
                        htmlInput: { spellCheck: false, style: { fontFamily: 'monospace' } }
                    }, onClick: onClick, inputRef: inputRef, onBlur: onBlur, onKeyUp: onKeyUp, fullWidth: true, label: "Code", value: steps, onChange: function (e) { return onChangeSteps(index, e.target.value); }, multiline: true, sx: { fontFamily: 'monospace' }, rows: steps.split('\n').length }), helpText && _jsx(Alert, { severity: helpSeverity, children: helpText })] }) }));
}
