var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { emptyFirmware, useAddFirmwareMutation, useUploadFirmwareMutation } from '../../../../../api/firmwareApi';
import { FileUpload, readUploadFile } from '../../../../../components/atoms/form/FileUpload';
import { PricerButton } from '../../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../../context/MessageContext';
import { AddVersionStep } from '../atoms/AddVersionStep';
export function AddFirmwareDialog(_a) {
    var _this = this;
    var open = _a.open, onCancel = _a.onCancel, product = _a.product;
    var _b = useMessageHandler(), getRTKError = _b.getRTKError, addError = _b.addError;
    var _c = React.useState(emptyFirmware), firmware = _c[0], setFirmware = _c[1];
    var _d = React.useState(0), step = _d[0], setStep = _d[1];
    var _e = React.useState(''), firmwareId = _e[0], setFirmwareId = _e[1];
    var _f = React.useState(undefined), file = _f[0], setFile = _f[1];
    var _g = React.useState(undefined), error = _g[0], setError = _g[1];
    var addFirmware = useAddFirmwareMutation()[0];
    var uploadFirmware = useUploadFirmwareMutation()[0];
    var onAddFirmware = function () { return __awaiter(_this, void 0, void 0, function () {
        var id, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addFirmware(__assign(__assign({}, firmware), { product: product })).unwrap()];
                case 1:
                    id = (_a.sent()).id;
                    setFirmwareId(id);
                    setError(undefined);
                    return [2 /*return*/, true];
                case 2:
                    e_1 = _a.sent();
                    setFirmwareId('');
                    setError("Failed to add firmware: ".concat(getRTKError(e_1)));
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onUploadFirmware = function () { return __awaiter(_this, void 0, void 0, function () {
        var firmware;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!file) {
                        setError('No file selected');
                        return [2 /*return*/];
                    }
                    setError(undefined);
                    return [4 /*yield*/, readUploadFile(file)];
                case 1:
                    firmware = _a.sent();
                    return [4 /*yield*/, uploadFirmware({
                            id: firmwareId,
                            filename: file.name,
                            firmware: firmware
                        }).unwrap()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onNext = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(step == 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onAddFirmware()];
                case 1:
                    if (_a.sent()) {
                        setStep(function (step) { return step + 1; });
                    }
                    return [3 /*break*/, 5];
                case 2:
                    if (!(step == 1)) return [3 /*break*/, 4];
                    return [4 /*yield*/, onUploadFirmware()];
                case 3:
                    _a.sent();
                    onDoCancel();
                    return [3 /*break*/, 5];
                case 4:
                    addError('Unknown step: ' + step);
                    setStep(0);
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onDoCancel = function () {
        setFirmware(emptyFirmware);
        setFile(undefined);
        setStep(0);
        setError(undefined);
        onCancel();
    };
    var onUpload = function (files) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setFile(files[0]);
            return [2 /*return*/, false];
        });
    }); };
    return (_jsxs(Dialog, { open: open, fullWidth: true, children: [_jsx(DialogTitle, { children: "Change address" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: _jsxs(Stack, { direction: "column", spacing: 3, children: [_jsxs(Stepper, { activeStep: step, children: [_jsx(Step, { children: _jsx(StepLabel, { children: "Create version" }) }, "version"), _jsx(Step, { children: _jsx(StepLabel, { children: "Upload firmware" }) }, "upload")] }), error && _jsx(Alert, { severity: "error", children: error }), step === 0 && (_jsx(AddVersionStep, { product: product, firmware: firmware, setFirmware: setFirmware })), step === 1 && (_jsx(FileUpload, { title: 'Click to upload firmware image', onUpload: onUpload }))] }) }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return onDoCancel(); }, children: "Cancel" }), _jsxs(PricerButton, { disabled: step === 1 && file === undefined, type: "ok", onClick: function () { return onNext(); }, children: [step === 0 && _jsx(_Fragment, { children: "Next" }), step === 1 && _jsx(_Fragment, { children: "Finish" })] })] })] }));
}
