import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import { RelativeDateText } from '../../../../../components/atoms/RelativeDateText';
import { DeviceHistoryIcon } from '../../atoms/DeviceHistoryIcon';
export function DeviceHistoryListItem(_a) {
    var log = _a.log;
    var title = "".concat(log.device, " - ").concat(log.ip);
    return (_jsxs(Card, { sx: { width: { sm: 1, md: 0.9 } }, children: [_jsx(CardHeader, { avatar: _jsx(DeviceHistoryIcon, { event: log.event }), title: title, subheader: _jsx(RelativeDateText, { showBoth: true, date: log.timestamp, variant: 'subtitle1' }) }), _jsx(CardContent, { children: log.message })] }, log.id));
}
