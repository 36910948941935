import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem } from '@mui/material';
import React from 'react';
import { SelectBox } from '../../../../components/atoms/form/SelectBox';
export function ProductSelector(_a) {
    var _b = _a.product, product = _b === void 0 ? '' : _b, setProduct = _a.setProduct;
    var fauProduxt = product || '$$EMPTY$$';
    var setFauProduxt = function (product) {
        return setProduct(product === '$$EMPTY$$' ? undefined : product);
    };
    return (_jsxs(SelectBox, { title: "Produxt", value: fauProduxt, setValue: setFauProduxt, children: [_jsx(MenuItem, { value: "$$EMPTY$$", children: "None" }), _jsx(MenuItem, { value: "OAP1", children: "StoreLink" })] }));
}
