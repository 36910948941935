import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Grid from '@mui/material/Grid2';
import React from 'react';
import StoreLink from '../../../../../common/assets/storelink.png';
import { BackButton } from '../../../../../components/atoms/toolbar/BackButton';
import { ToolWidget } from '../../../../../components/atoms/ToolWidget';
import { SimplePageContent } from '../../../../../components/templates/SimplePageContent';
export function SelectProductView() {
    return (_jsx(SimplePageContent, { hasPaper: false, toolbar: _jsx(_Fragment, { children: _jsx(BackButton, { path: "/infra" }) }), children: _jsx(Grid, { container: true, children: _jsx(ToolWidget, { title: "OAP1", description: "Callisto/OAP1.", image: StoreLink, link: '/infra/firmwares/OAP1' }) }) }));
}
