import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Grid from '@mui/material/Grid2';
import React from 'react';
import { useNavigate } from 'react-router';
import { PricerPagination } from '../../../../components/atoms/PricerPagination';
import { ItemListItem } from '../atoms/ItemListItem';
export function ListItemView(_a) {
    var items = _a.items, page = _a.page, setPage = _a.setPage;
    var navigator = useNavigate();
    var onClick = function (item) { return navigator("/items/".concat(item.itemId)); };
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, spacing: 2, children: [items.content.map(function (c) { return (_jsx(ItemListItem, { item: c, onClick: onClick }, c.itemId)); }), items.content.length === 0 && _jsx(Grid, { children: "No items found" })] }), _jsx(PricerPagination, { items: items, page: page, setPage: setPage })] }));
}
