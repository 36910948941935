import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router';
import { useGetLabelQuery } from '../../../../api/labelApi';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { DeleteLabelDialog } from '../ManageLabelView/molecules/DeleteLabelDialog';
import { FirmwareUpgradeDialog } from '../ManageLabelView/molecules/FirmwareUpgradeDialog';
import { IRFirmwareUpgradeDialog } from '../ManageLabelView/molecules/IRFirmwareUpgradeDialog';
import { LabelToolBar } from '../ManageLabelView/molecules/LabelToolBar';
import { SetupLabelDialog } from '../ManageLabelView/molecules/SetupLabelDialog';
export function useLabel() {
    return useOutletContext();
}
export function ManageLabelView() {
    var id = useParams().id;
    var addError = useMessageHandler().addError;
    var _a = React.useState(true), found = _a[0], setFound = _a[1];
    var _b = React.useState('auto'), target = _b[0], setTarget = _b[1];
    var _c = React.useState(false), showSetup = _c[0], setShowSetup = _c[1];
    var _d = React.useState(false), showUpgrade = _d[0], setShowUpgrade = _d[1];
    var _e = React.useState(false), showIRUpgrade = _e[0], setShowIRUpgrade = _e[1];
    var _f = React.useState(false), showDelete = _f[0], setShowDelete = _f[1];
    var _g = useGetLabelQuery(id || ''), label = _g.data, isFetching = _g.isFetching, isError = _g.isError;
    useEffect(function () {
        if (isError) {
            addError("Failed to fetch ".concat(id));
        }
    }, [isError, addError, id]);
    useEffect(function () {
        if (label === undefined) {
            return;
        }
        if (label.irTargets === undefined || label.irTargets.length === 0) {
            setFound(false);
        }
        else {
            setFound(true);
        }
    }, [label]);
    return (_jsx(SimplePageContent, { isError: isError, wrapPaper: false, errorMessage: "Failed to fetch access-point", toolbar: label && (_jsx(LabelToolBar, { label: label, isFetching: isFetching, target: target, setTarget: setTarget, setShowSetup: setShowSetup, setShowUpgrade: setShowUpgrade, setShowIRUpgrade: setShowIRUpgrade, setShowDelete: setShowDelete })), children: label && (_jsxs(_Fragment, { children: [_jsx(SetupLabelDialog, { label: label, target: target, open: showSetup, onClose: function () { return setShowSetup(false); } }), _jsx(FirmwareUpgradeDialog, { label: label, open: showUpgrade, onClose: function () { return setShowUpgrade(false); } }), _jsx(IRFirmwareUpgradeDialog, { label: label, target: target, open: showIRUpgrade, onClose: function () { return setShowIRUpgrade(false); } }), _jsx(DeleteLabelDialog, { label: label, open: showDelete, onClose: function () { return setShowDelete(false); } }), _jsx(Outlet, { context: { label: label, found: found, target: target } })] })) }));
}
