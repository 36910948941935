var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, IconButton, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useDeleteIRTargetMutation, useGetIRTargetsQuery, useSaveIRTargetMutation } from '../../../../api/api';
import { useMessageHandler } from '../../../../context/MessageContext';
export function EditTransceivers(_a) {
    var _this = this;
    var _b;
    var accessPoint = _a.accessPoint;
    var _c = useMessageHandler(), addError = _c.addError, addApiError = _c.addApiError;
    var _d = React.useState([]), IRTargets = _d[0], setIRTargets = _d[1];
    var saveTarget = useSaveIRTargetMutation()[0];
    var deleteTarget = useDeleteIRTargetMutation()[0];
    var targets = useGetIRTargetsQuery({ page: 0, size: 100 }).data;
    var allTargetTransceivers = ((_b = targets === null || targets === void 0 ? void 0 : targets.content) === null || _b === void 0 ? void 0 : _b.filter(function (t) { return t.accessPoint === accessPoint.id; }).map(function (t) { return t.transceivers; }).flat(1)) || [];
    var allApTransceivers = accessPoint.transceivers;
    var allMissingTransceivers = allApTransceivers.filter(function (t) { return !allTargetTransceivers.includes(t); });
    useEffect(function () {
        var _a;
        setIRTargets(((_a = targets === null || targets === void 0 ? void 0 : targets.content) === null || _a === void 0 ? void 0 : _a.filter(function (t) { return t.accessPoint === accessPoint.id; })) || []);
    }, [targets, accessPoint]);
    var onDeleteIRTarget = function (id) { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteTarget(id).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to delete ir target", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSaveTarget = function (id) { return __awaiter(_this, void 0, void 0, function () {
        var target, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!id) {
                        return [2 /*return*/];
                    }
                    target = IRTargets === null || IRTargets === void 0 ? void 0 : IRTargets.find(function (t) { return t.id === id; });
                    if (!target) {
                        addError("Failed to save ir target: ".concat(id));
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, saveTarget(target).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    addApiError("Failed to save target", e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onAddTarget = function () { return __awaiter(_this, void 0, void 0, function () {
        var newTarget, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newTarget = {
                        id: uuid(),
                        accessPoint: accessPoint.id,
                        title: 'new-target',
                        tenant: '',
                        transceivers: []
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, saveTarget(newTarget).unwrap()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    addApiError("Failed to add target", e_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onChangeTrx = function (id, trx) { return __awaiter(_this, void 0, void 0, function () {
        var target, save, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id || !trx) {
                        return [2 /*return*/];
                    }
                    target = IRTargets === null || IRTargets === void 0 ? void 0 : IRTargets.find(function (t) { return t.id === id; });
                    if (!target) {
                        addError("Failed to save ir target: ".concat(id));
                        return [2 /*return*/];
                    }
                    save = __assign(__assign({}, target), { transceivers: trx || [] });
                    setIRTargets(function (t) { return t.map(function (t2) { return (t2.id === id ? save : t2); }); });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, saveTarget(save).unwrap()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    addApiError("Failed to add target", e_4);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var changeTargetName = function (id, name) {
        if (!id || !name) {
            return;
        }
        setIRTargets(IRTargets === null || IRTargets === void 0 ? void 0 : IRTargets.map(function (t) { return (t.id === id ? __assign(__assign({}, t), { title: name }) : t); }));
    };
    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "targets-content", id: "targets-header", children: _jsx(Typography, { children: "IR Targets" }) }), _jsx(AccordionDetails, { children: _jsxs(Stack, { spacing: 3, children: [allMissingTransceivers && allMissingTransceivers.length > 0 && (_jsx(Alert, { severity: "warning", children: "You have unused transceivers" })), _jsx(Alert, { severity: "warning", children: "Currently removing a target which has labels will cause labels to become broken, relocate labels afterwards." }), IRTargets.map(function (t) { return (_jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(TextField, { label: "Name", value: t.title, size: "small", onChange: function (e) { return changeTargetName(t.id, e.target.value); }, onBlur: function () { return onSaveTarget(t.id); } }), accessPoint.type !== 'radio' && (_jsxs(ToggleButtonGroup, { value: t.transceivers, size: "small", onChange: function (e, v) { return onChangeTrx(t.id, v); }, children: [t.transceivers, accessPoint.transceivers.map(function (trx) { return (_jsx(ToggleButton, { value: trx, children: trx }, "".concat(trx))); })] })), _jsx(IconButton, { edge: "end", "aria-label": "delete", onClick: function () { return onDeleteIRTarget(t.id || ''); }, children: _jsx(DeleteIcon, {}) })] }, t.id)); }), _jsxs(Button, { variant: "outlined", color: "secondary", onClick: onAddTarget, children: [_jsx(AddIcon, {}), " Add new target"] })] }) })] }));
}
