import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { relativeTime, toEuropeDate, toIsoDate } from '../../common/helpers';
export function RelativeDateText(_a) {
    var date = _a.date, _b = _a.variant, variant = _b === void 0 ? 'body1' : _b, _c = _a.showBoth, showBoth = _c === void 0 ? false : _c, _d = _a.placement, placement = _d === void 0 ? 'bottom-start' : _d;
    var suffix = date === undefined ? '' : " (".concat(toEuropeDate(date), ")");
    var text = showBoth ? "".concat(relativeTime(date)).concat(suffix) : relativeTime(date);
    return (_jsx(Tooltip, { title: toIsoDate(date), placement: placement, children: _jsx(Typography, { variant: variant, children: text }) }));
}
