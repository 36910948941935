import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Box, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getRTKError } from '../../api/helpers';
import { useMessageHandler } from '../../context/MessageContext';
import { useZoneContext } from '../../context/ZoneContext';
import { PricerToolbar } from '../atoms/toolbar/PricerToolbar';
import { ErrorDialog } from '../molecules/ErrorDialog';
export function SimplePageContent(_a) {
    var apiError = _a.apiError, _b = _a.errorMessage, errorMessage = _b === void 0 ? 'Error' : _b, _c = _a.isError, isError = _c === void 0 ? false : _c, _d = _a.isFetching, isFetching = _d === void 0 ? false : _d, _e = _a.isEmpty, isEmpty = _e === void 0 ? false : _e, _f = _a.emptyMessage, emptyMessage = _f === void 0 ? 'No data found' : _f, toolbar = _a.toolbar, children = _a.children, _g = _a.needZone, needZone = _g === void 0 ? false : _g, _h = _a.hasPaper, hasPaper = _h === void 0 ? true : _h, _j = _a.wrapPaper, wrapPaper = _j === void 0 ? true : _j;
    var navigator = useNavigate();
    var _k = useMessageHandler(), addError = _k.addError, addApiError = _k.addApiError;
    var zoneId = useZoneContext().zoneId;
    var error = apiError !== undefined
        ? "".concat(errorMessage, ": ").concat(getRTKError(apiError))
        : errorMessage;
    useEffect(function () {
        if (isError && apiError) {
            addApiError(errorMessage, apiError);
        }
        else if (isError) {
            addError(errorMessage);
        }
    }, [isError, apiError, errorMessage, addError, addApiError]);
    if (hasPaper && wrapPaper) {
        children = (_jsxs(Paper, { sx: { p: 3, width: { xs: 1, md: 0.9 } }, children: [" ", children, " "] }));
    }
    else if (hasPaper) {
        children = _jsxs(Box, { sx: { width: { xs: 1, md: 0.9 } }, children: [" ", children] });
    }
    if (needZone && !zoneId) {
        children = (_jsx(Paper, { sx: { p: 3, width: { xs: 1, md: 0.9 } }, children: _jsx(Alert, { severity: "warning", children: "Please select a zone first" }) }));
    }
    return (_jsxs(_Fragment, { children: [isError && _jsx(ErrorDialog, { text: error, onClose: function () { return navigator('/'); } }), toolbar && (_jsx(PricerToolbar, { sx: { width: { xs: 1, md: 0.9 } }, children: toolbar })), isEmpty && !isFetching && (_jsx(Paper, { sx: { p: 3, width: { xs: 1, md: 0.9 } }, children: _jsx(Alert, { severity: "warning", children: emptyMessage }) })), children] }));
}
