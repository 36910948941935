export function getApiStatusCode(error) {
    if (!error) {
        return 0;
    }
    var fetchError = error;
    if (fetchError.status === undefined) {
        return -1;
    }
    if (typeof fetchError.status === 'number') {
        return fetchError.status;
    }
    if (fetchError.status === 'PARSING_ERROR') {
        return fetchError.originalStatus;
    }
    return -1;
}
export function transformPaginatedResponse(apiResponse, meta) {
    return {
        content: apiResponse,
        count: Number(meta.response.headers.get('X-Total-Elements')),
        pages: Number(meta.response.headers.get('X-Total-Pages'))
    };
}
export var isAccessDenied = function (error) { return [401, 403].includes(getApiStatusCode(error)); };
export var getRTKError = function (e) {
    if (e.data !== undefined && e.data.error !== undefined) {
        return e.data.error;
    }
    if (e.status !== undefined) {
        return '' + e.status;
    }
    return 'Unknown error';
};
export var getRTKStatusCode = function (e) {
    if (e.data !== undefined && e.data.status !== undefined) {
        return e.data.status;
    }
    if (e.status !== undefined) {
        return e.status;
    }
    return -1;
};
export var responseHandler = function (response) {
    if (response.status === 400 ||
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404) {
        return new Promise(function (resolve) {
            response.text().then(function (text) {
                resolve({ error: text, status: response.status });
            });
        });
    }
    return Promise.resolve(response.json());
};
export var responseHandlerText = function (response) {
    if (response.status === 400 ||
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404) {
        return new Promise(function (resolve) {
            response.text().then(function (text) {
                resolve({ error: text, status: response.status });
            });
        });
    }
    return Promise.resolve(response.text());
};
