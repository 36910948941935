import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Stack } from '@mui/material';
import React from 'react';
import { useMessageHandler } from '../../../../../../context/MessageContext';
import { KeyValueList } from '../atoms/KeyValueList';
import { UDDAccordion } from '../atoms/UDDAccordion';
import { exportUdd, importUdd } from '../ConfigProcessor';
import { ChunkList } from '../molecules/ChunkList';
import { ConfigEditor } from '../molecules/ConfigEditor';
import { MetadataEditor } from '../molecules/MetadataEditor';
var sequenceOffset = 10;
export function UDDView() {
    var _a = React.useState(1), expanded = _a[0], setExpanded = _a[1];
    var _b = React.useState([]), sequence = _b[0], setSequence = _b[1];
    var _c = React.useState([]), constants = _c[0], setConstants = _c[1];
    var _d = React.useState([]), commands = _d[0], setCommands = _d[1];
    var _e = React.useState(), metadata = _e[0], setMetadata = _e[1];
    var _f = React.useState(), config = _f[0], setConfig = _f[1];
    var addError = useMessageHandler().addError;
    var importYaml = function () {
        navigator.clipboard
            .readText()
            .then(function (text) {
            try {
                var config_1 = importUdd(text);
                setConstants(config_1.constants);
                setCommands(config_1.commands);
                setSequence(config_1.sequence);
                setMetadata(config_1.metadata);
                setConfig(config_1.config);
            }
            catch (e) {
                addError('Failed to parse YAML: ' + e);
            }
        })
            .catch(function (err) {
            addError('Failed to read clipboard contents: ', err);
        });
    };
    var exportYaml = function () {
        var data = exportUdd({
            sequence: sequence,
            constants: constants,
            commands: commands,
            metadata: metadata,
            config: config
        });
        navigator.clipboard
            .writeText(data)
            .then(function () {
            console.log('YAML copied to clipboard');
        })
            .catch(function (err) {
            addError('Failed to write clipboard contents: ', err);
        });
    };
    var deleteAll = function () {
        setConstants([]);
        setCommands([]);
        setSequence([]);
        setMetadata(undefined);
        setConfig(undefined);
    };
    return (_jsxs(Box, { children: [_jsx(UDDAccordion, { index: 1, expanded: expanded, setExpanded: setExpanded, title: "Import/Export", icon: _jsx(FileUploadIcon, {}), children: _jsx(Stack, { direction: "column", spacing: 3, children: _jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(Button, { onClick: importYaml, startIcon: _jsx(ContentPasteIcon, {}), children: "Import YAML from clip-board" }), _jsx(Button, { onClick: exportYaml, startIcon: _jsx(ContentCopyIcon, {}), children: "Export YAML to clip-board" }), _jsx(Button, { startIcon: _jsx(FileUploadIcon, {}), children: "Send to label" }), _jsx(Button, { onClick: deleteAll, startIcon: _jsx(DeleteIcon, {}), children: "Delete all" })] }) }) }), _jsx(UDDAccordion, { index: 2, expanded: expanded, setExpanded: setExpanded, title: "Metadata", icon: _jsx(InfoIcon, {}), children: _jsx(MetadataEditor, { value: metadata, setValue: setMetadata }) }), _jsx(UDDAccordion, { index: 3, expanded: expanded, setExpanded: setExpanded, title: "Configuration", icon: _jsx(SettingsIcon, {}), children: _jsx(ConfigEditor, { value: config, setValue: setConfig }) }), _jsx(UDDAccordion, { index: 4, expanded: expanded, setExpanded: setExpanded, title: "Commands (aliases)", icon: _jsx(EditNoteIcon, {}), children: _jsx(KeyValueList, { value: commands, keyName: "Constant", onChange: function (value) { return setCommands(value); } }) }), _jsx(UDDAccordion, { index: 5, expanded: expanded, setExpanded: setExpanded, title: "Constants (values)", icon: _jsx(EditNoteIcon, {}), children: _jsx(KeyValueList, { value: constants, keyName: "Constant", onChange: function (value) { return setConstants(value); } }) }), _jsx(ChunkList, { sequenceOffset: sequenceOffset, sequence: sequence, setSequence: setSequence, expanded: expanded, setExpanded: setExpanded })] }));
}
