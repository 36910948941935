import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { useNavigate } from 'react-router';
export function ToolWidget(_a) {
    var link = _a.link, title = _a.title, description = _a.description, image = _a.image;
    var navigator = useNavigate();
    return (_jsx(Grid, { size: { xs: 12, sm: 3 }, sx: { m: 1 }, children: _jsx(Card, { sx: { maxWidth: 345 }, children: _jsxs(CardActionArea, { onClick: function () { return navigator(link); }, children: [_jsx(CardMedia, { sx: { height: 140 }, image: image }), _jsxs(CardContent, { children: [_jsx(Typography, { gutterBottom: true, variant: "h5", component: "div", children: title }), _jsx(Typography, { sx: { display: { xs: 'none', sm: 'block' } }, variant: "body2", color: "text.secondary", children: description })] })] }) }) }));
}
