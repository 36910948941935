import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SensorsIcon from '@mui/icons-material/Sensors';
import { Button } from '@mui/material';
import React from 'react';
import { useIRBasestationContext } from '../../../../context/IRBasestationContext';
import { IRBaseStationLog } from '../../ir/IRBaseStationLog';
export function IRBaseStation() {
    var _a = useIRBasestationContext(), available = _a.available, isConnected = _a.isConnected, isIrConnected = _a.isIrConnected;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var title = isIrConnected
        ? 'Show IR Details'
        : 'Connect Local Base station';
    if (!available) {
        return _jsx(_Fragment, { children: "IR Not available" });
    }
    if (!isConnected) {
        return _jsx(_Fragment, { children: "Not connected to backend..." });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Button, { sx: { display: { xs: 'none', sm: 'none', md: 'inline-flex' } }, variant: "contained", startIcon: _jsx(SensorsIcon, {}), onClick: function () { return setOpen(true); }, children: title }), _jsx(IRBaseStationLog, { open: open, onClose: function () { return setOpen(false); } })] }));
}
