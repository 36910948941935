import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { FormArea } from '../../../../../components/atoms/form/FormArea';
import { PricerButton } from '../../../../../components/atoms/PricerButton';
import { FirmwareSelector } from '../../atoms/FirmwareSelector';
import { FirmwareDetails } from './FirmwareDetails';
export function SelectFirmwareDialog(_a) {
    var open = _a.open, onCancel = _a.onCancel, product = _a.product, firmwareId = _a.firmwareId, onSave = _a.onSave, setFirmware = _a.setFirmware;
    return (_jsxs(Dialog, { open: open, fullWidth: true, children: [_jsx(DialogTitle, { children: "Change address" }), _jsx(DialogContent, { children: _jsxs(DialogContentText, { children: [_jsx(FormArea, { children: _jsx(FirmwareSelector, { setFirmware: setFirmware, product: product, firmwareId: firmwareId }) }), firmwareId && _jsx(FirmwareDetails, { id: firmwareId })] }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return onCancel(); }, children: "Cancel" }), _jsx(PricerButton, { type: "ok", onClick: onSave, children: "Save" })] })] }));
}
