var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './helpers';
export var itemApi = createApi({
    reducerPath: 'itemApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: ['Items', 'Item', 'Links'],
    endpoints: function (builder) { return ({
        // Link API
        getLinkForItem: builder.query({
            query: function (itemId) { return ({
                url: "/label/api/v1/stores/store/items/".concat(itemId, "/links"),
                responseHandler: responseHandler
            }); },
            providesTags: ['Links']
        }),
        getLinkForLabel: builder.query({
            query: function (barcode) { return ({
                url: "/label/api/v1/stores/store/labels/".concat(barcode, "/links"),
                responseHandler: responseHandler
            }); },
            providesTags: ['Links']
        }),
        linkItem: builder.mutation({
            query: function (_a) {
                var barcode = _a.barcode, itemId = _a.itemId;
                return ({
                    url: "label/api/v1/stores/store/labels/".concat(barcode, "/links/").concat(itemId),
                    method: 'POST',
                    body: {},
                    responseHandler: 'text'
                });
            },
            invalidatesTags: function (result, error, _a) {
                var itemId = _a.itemId;
                return [
                    { type: 'Item', id: itemId },
                    { type: 'Items' },
                    { type: 'Links' }
                ];
            }
        }),
        unLinkItem: builder.mutation({
            query: function (_a) {
                var barcode = _a.barcode, itemId = _a.itemId;
                return ({
                    url: "label/api/v1/stores/store/labels/".concat(barcode, "/links/").concat(itemId),
                    method: 'DELETE',
                    responseHandler: 'text'
                });
            },
            invalidatesTags: function (result, error, _a) {
                var itemId = _a.itemId;
                return [
                    { type: 'Item', id: itemId },
                    { type: 'Items' },
                    { type: 'Links' }
                ];
            }
        }),
        getTransmissionQueue: builder.query({
            query: function (barcode) { return ({
                url: "label/api/v1/stores/store/labels/".concat(barcode),
                responseHandler: responseHandler
            }); },
            providesTags: ['Links']
        }),
        updateTransmissionQueue: builder.mutation({
            query: function (_a) {
                var barcode = _a.barcode, action = _a.action;
                return ({
                    url: "label/api/v1/stores/store/labels/".concat(barcode),
                    method: 'PUT',
                    body: {
                        action: action,
                        barcode: barcode
                    },
                    responseHandler: responseHandler
                });
            }
        }),
        // Item API
        getItems: builder.query({
            query: function (page) { return ({
                url: "item/api/v1/stores/store/items?page=".concat(page),
                responseHandler: responseHandler
            }); },
            transformResponse: (transformPaginatedResponse),
            providesTags: ['Items']
        }),
        getItem: builder.query({
            query: function (id) { return ({
                url: "item/api/v1/stores/store/items/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Item', id: id }]; }
        }),
        saveItem: builder.mutation({
            query: function (item) { return ({
                url: "item/api/v1/stores/store/items/".concat(item.itemId),
                method: 'PUT',
                body: __assign(__assign({}, item), { itemId: undefined }),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var itemId = _a.itemId;
                return [
                    { type: 'Item', id: itemId },
                    { type: 'Items' }
                ];
            }
        }),
        addItem: builder.mutation({
            query: function (item) { return ({
                url: "item/api/v1/stores/store/items",
                method: 'POST',
                body: __assign(__assign({}, item), { itemId: undefined }),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var itemId = _a.itemId;
                return [
                    { type: 'Item', id: itemId },
                    { type: 'Items' }
                ];
            }
        }),
        deleteItem: builder.mutation({
            query: function (id) { return ({
                url: "item/api/v1/stores/store/items/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return [
                { type: 'Item', id: id },
                { type: 'Items' }
            ]; }
        })
    }); }
});
export var useGetItemQuery = itemApi.useGetItemQuery, useGetItemsQuery = itemApi.useGetItemsQuery, useSaveItemMutation = itemApi.useSaveItemMutation, useAddItemMutation = itemApi.useAddItemMutation, useDeleteItemMutation = itemApi.useDeleteItemMutation, useLinkItemMutation = itemApi.useLinkItemMutation, useGetLinkForItemQuery = itemApi.useGetLinkForItemQuery, useGetLinkForLabelQuery = itemApi.useGetLinkForLabelQuery, useUnLinkItemMutation = itemApi.useUnLinkItemMutation, useGetTransmissionQueueQuery = itemApi.useGetTransmissionQueueQuery, useUpdateTransmissionQueueMutation = itemApi.useUpdateTransmissionQueueMutation;
