import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetIRZonesQuery } from '../../api/api';
import { useMessageHandler } from '../../context/MessageContext';
import { useZoneContext } from '../../context/ZoneContext';
export function ZoneMenu() {
    var _a;
    var addError = useMessageHandler().addError;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = useZoneContext(), zoneId = _c.zoneId, setZoneId = _c.setZoneId, setZoneTitle = _c.setZoneTitle;
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var updateZone = function (zoneId, zoneTitle) {
        setZoneId(zoneId);
        setZoneTitle(zoneTitle);
        setAnchorEl(null);
    };
    var _d = useGetIRZonesQuery(), irZones = _d.data, failedToFetchZones = _d.isError;
    useEffect(function () {
        if (failedToFetchZones) {
            addError('Failed to fetch ir zones');
        }
    }, [failedToFetchZones, addError]);
    var currentTitle = ((_a = irZones === null || irZones === void 0 ? void 0 : irZones.find(function (zone) { return zone.id === zoneId; })) === null || _a === void 0 ? void 0 : _a.title) || 'All zones';
    return (_jsx(_Fragment, { children: irZones && (_jsxs(_Fragment, { children: [_jsx(Button, { id: "select-zone-button", "aria-controls": open ? 'basic-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, onClick: handleClick, children: currentTitle }), _jsxs(Menu, { id: "select-zone-button", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                        'aria-labelledby': 'select-zone-button'
                    }, children: [_jsx(MenuItem, { onClick: function () { return updateZone('', ''); }, children: "All zones" }), irZones === null || irZones === void 0 ? void 0 : irZones.map(function (zone) { return (_jsx(MenuItem, { onClick: function () { return updateZone(zone.id, zone.title); }, children: zone.title }, zone.id)); })] })] })) }));
}
