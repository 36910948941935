import { jsx as _jsx } from "react/jsx-runtime";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ArchiveIcon from '@mui/icons-material/Archive';
import ErrorIcon from '@mui/icons-material/Error';
import ImageIcon from '@mui/icons-material/Image';
import MemoryIcon from '@mui/icons-material/Memory';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import React from 'react';
export function MessageIcon(_a) {
    var type = _a.type, status = _a.status;
    switch (status) {
        case 'error':
            return _jsx(ErrorIcon, {});
        case 'pending':
            return _jsx(PendingActionsIcon, {});
    }
    switch (type) {
        case 'command':
            return _jsx(TaskAltIcon, {});
        case 'write':
            return _jsx(ImageIcon, {});
        case 'writeObject':
            return _jsx(MemoryIcon, {});
        case 'written':
            return _jsx(ImageIcon, {});
        case 'objectWritten':
            return _jsx(MemoryIcon, {});
        case 'events':
            return _jsx(ArchiveIcon, {});
        case 'assignment':
            return _jsx(AltRouteIcon, {});
        case 'info':
            return _jsx(PermDeviceInformationIcon, {});
        default:
            return _jsx(QuestionMarkIcon, {});
    }
}
