var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { emptyConfig } from '../ConfigProcessor';
export function ConfigEditor(_a) {
    var _b = _a.value, value = _b === void 0 ? emptyConfig : _b, setValue = _a.setValue;
    var udd = value.udd;
    var display = value.display_config;
    var temperature = value.temperature_config;
    var setUDD = function (uddValue) {
        setValue(__assign(__assign({}, value), { udd: uddValue }));
    };
    var setDisplay = function (displayValue) {
        setValue(__assign(__assign({}, value), { display_config: displayValue }));
    };
    var setTemperature = function (temperatureValue) {
        setValue(__assign(__assign({}, value), { temperature_config: temperatureValue }));
    };
    return (_jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { size: 12, children: _jsx(Typography, { variant: "h5", children: "UDD" }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Engine version:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: udd.engine_version, onChange: function (e) { return setUDD(__assign(__assign({}, udd), { engine_version: e.target.value })); } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Package id:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: udd.package_id, onChange: function (e) { return setUDD(__assign(__assign({}, udd), { package_id: e.target.value })); } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Package version:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: udd.package_version, onChange: function (e) { return setUDD(__assign(__assign({}, udd), { package_version: e.target.value })); } }) }), _jsx(Grid, { size: 12, children: _jsx(Typography, { variant: "h5", children: "Display" }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Busy pin is inverted:" }) }), _jsx(Grid, { size: 10, children: _jsx(Checkbox, { checked: display.busy_pin_is_inverted, onChange: function (e) {
                        return setDisplay(__assign(__assign({}, display), { busy_pin_is_inverted: e.target.checked }));
                    } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "SPI CS byte Toggle:" }) }), _jsx(Grid, { size: 10, children: _jsx(Checkbox, { checked: display.spi_cs_byte_toggle_enabled, onChange: function (e) {
                        return setDisplay(__assign(__assign({}, display), { spi_cs_byte_toggle_enabled: e.target.checked }));
                    } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Scan type:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: display.scan_type, onChange: function (e) {
                        return setDisplay(__assign(__assign({}, display), { scan_type: e.target.value }));
                    } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "SPI Bus clock speed:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: display.spi_clk_speed, onChange: function (e) {
                        return setDisplay(__assign(__assign({}, display), { spi_clk_speed: e.target.value }));
                    } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "SPI bus mode:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: display.spi_mode, onChange: function (e) { return setDisplay(__assign(__assign({}, display), { spi_mode: e.target.value })); } }) }), _jsx(Grid, { size: 12, children: _jsx(Typography, { variant: "h5", children: "Temperature" }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Format:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: temperature.format, onChange: function (e) {
                        return setTemperature(__assign(__assign({}, temperature), { format: e.target.value }));
                    } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Minimum:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: temperature.minimum, onChange: function (e) {
                        return setTemperature(__assign(__assign({}, temperature), { minimum: parseInt(e.target.value) }));
                    } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Maximum:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: temperature.maximum, onChange: function (e) {
                        return setTemperature(__assign(__assign({}, temperature), { maximum: parseInt(e.target.value) }));
                    } }) })] }));
}
