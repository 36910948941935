import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
export function OnlineAccessPointDetails(_a) {
    var accessPoint = _a.accessPoint;
    var transceivers = accessPoint.onlineTransceivers || [];
    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "config-content", id: "config-header", children: _jsx(Typography, { children: "Transceivers" }) }), _jsx(AccordionDetails, { children: _jsx(List, { children: transceivers.map(function (trx) { return (_jsxs(ListItem, { disablePadding: true, children: [_jsx(ListItemIcon, { children: _jsx(DeveloperBoardIcon, {}) }), _jsx(ListItemText, { primary: "".concat(+trx.id, ", ").concat(trx.type), secondary: "".concat(trx.hardwareId, ", ").concat(trx.softwareId) })] }, "trx-".concat(trx.id))); }) }) })] }));
}
