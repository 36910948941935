import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './helpers';
export var deviceHistoryApi = createApi({
    reducerPath: 'deviceHistoryApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: ['Histories', 'History'],
    endpoints: function (builder) { return ({
        getHistoriesForDevice: builder.query({
            query: function (_a) {
                var id = _a.id, page = _a.page;
                return ({
                    url: "infraconfig/api/v1/devices/".concat(id, "/logs?page=").concat(page),
                    responseHandler: responseHandler
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: ['Histories']
        }),
        getHistories: builder.query({
            query: function (_a) {
                var filter = _a.filter, page = _a.page;
                return ({
                    url: "infraconfig/api/v1/logs?page=".concat(page, "&filter=").concat(filter),
                    responseHandler: responseHandler
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: ['Histories']
        }),
        getHistory: builder.query({
            query: function (id) { return ({
                url: "infraconfig/api/v1/logs/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'History', id: id }]; }
        }),
        deleteHistory: builder.mutation({
            query: function (id) { return ({
                url: "infraconfig/api/v1/logs/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return [
                { type: 'History', id: id },
                { type: 'Histories' }
            ]; }
        })
    }); }
});
export var useGetHistoryQuery = deviceHistoryApi.useGetHistoryQuery, useGetHistoriesForDeviceQuery = deviceHistoryApi.useGetHistoriesForDeviceQuery, useGetHistoriesQuery = deviceHistoryApi.useGetHistoriesQuery, useDeleteHistoryMutation = deviceHistoryApi.useDeleteHistoryMutation;
