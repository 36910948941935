var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { emptyDevice, useAddDeviceMutation } from '../../../../../api/deviceApi';
import { FormArea } from '../../../../../components/atoms/form/FormArea';
import { TagEditor } from '../../../../../components/atoms/form/TagEditor';
import { PricerButton } from '../../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../../context/MessageContext';
export function AddDeviceDialog(_a) {
    var _this = this;
    var open = _a.open, onCancel = _a.onCancel;
    var navigator = useNavigate();
    var getRTKError = useMessageHandler().getRTKError;
    var _b = React.useState(emptyDevice), device = _b[0], setDevice = _b[1];
    var _c = React.useState(undefined), error = _c[0], setError = _c[1];
    var addDevice = useAddDeviceMutation()[0];
    var onSave = function (item) { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addDevice(__assign({}, item)).unwrap()];
                case 1:
                    _a.sent();
                    navigator('/infra/devices');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    setError("Failed to add device: ".concat(getRTKError(e_1)));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Dialog, { open: open, fullWidth: true, children: [_jsx(DialogTitle, { children: "Add new device" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: _jsxs(FormArea, { children: [_jsx(TextField, { label: "Hardware ID", variant: "outlined", value: device.id, onChange: function (e) {
                                    return setDevice(__assign(__assign({}, device), { id: e.target.value }));
                                }, autoFocus: true }), _jsx(TextField, { label: "Title", variant: "outlined", value: device.title, onChange: function (e) {
                                    return setDevice(__assign(__assign({}, device), { title: e.target.value }));
                                } }), _jsx(TagEditor, { title: "Tags", tags: device.tags || [], setTags: function (tags) {
                                    return setDevice(__assign(__assign({}, device), { tags: tags }));
                                } }), _jsx(TextField, { label: "Secret", variant: "outlined", value: device.secret, onChange: function (e) {
                                    return setDevice(__assign(__assign({}, device), { secret: e.target.value }));
                                } }), _jsx(TextField, { label: "Tenant", variant: "outlined", value: device.tenant, onChange: function (e) {
                                    return setDevice(__assign(__assign({}, device), { tenant: e.target.value }));
                                } }), _jsx(TextField, { label: "Address", variant: "outlined", value: device.address, onChange: function (e) {
                                    return setDevice(__assign(__assign({}, device), { address: e.target.value }));
                                } }), error && _jsx(Alert, { severity: "error", children: error })] }) }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return onCancel(); }, children: "Cancel" }), _jsx(PricerButton, { type: "ok", onClick: function () { return onSave(device); }, children: "Save" })] })] }));
}
