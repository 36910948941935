import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React from 'react';
import { useGetHistoriesForDeviceQuery } from '../../../../../api/deviceHistoryApi';
import { PricerPagination } from '../../../../../components/atoms/PricerPagination';
import { RelativeDateText } from '../../../../../components/atoms/RelativeDateText';
import { DeviceHistoryIcon } from '../../atoms/DeviceHistoryIcon';
export function DeviceHistory(_a) {
    var device = _a.device;
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    var _c = useGetHistoriesForDeviceQuery({
        id: device.id,
        page: page
    }), messages = _c.data, isFetching = _c.isFetching, isError = _c.isError;
    return (_jsx(_Fragment, { children: messages && !isFetching && !isError && (_jsxs(Stack, { direction: "column", alignItems: "center", children: [_jsx(List, { children: messages.content.map(function (m) { return (_jsxs(ListItem, { children: [_jsx(ListItemIcon, { children: _jsx(DeviceHistoryIcon, { event: m.event }) }), _jsx(ListItemText, { primary: m.message, secondary: _jsx(RelativeDateText, { showBoth: true, date: m.timestamp, variant: 'subtitle1' }) })] }, m.id)); }) }), _jsx(PricerPagination, { items: messages, page: page, setPage: setPage })] })) }));
}
