var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, responseHandlerText, transformPaginatedResponse } from './helpers';
var pageSize = 10;
export var transmissionApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: [
        'Users',
        'User',
        'Message',
        'Messages',
        'Metrics',
        'Queues',
        'IRTarget',
        'IRTargets',
        'IRZone',
        'LabelTypes'
    ],
    endpoints: function (builder) { return ({
        getMetrics: builder.query({
            query: function () { return ({
                url: 'transmission/api/v1/metrics',
                responseHandler: responseHandler
            }); },
            providesTags: ['Metrics']
        }),
        getQueues: builder.query({
            query: function () { return ({
                url: 'transmission/api/v1/rabbit-queues',
                responseHandler: responseHandler
            }); },
            providesTags: ['Queues']
        }),
        getUsers: builder.query({
            query: function () { return ({
                url: 'transmission/api/v1/users',
                responseHandler: responseHandler
            }); },
            providesTags: ['Users']
        }),
        getUser: builder.query({
            query: function (id) { return ({
                url: "transmission/api/v1/users/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'User', id: id }]; }
        }),
        saveUser: builder.mutation({
            query: function (user) { return ({
                url: "transmission/api/v1/users/".concat(user.id),
                method: 'PUT',
                body: __assign(__assign({}, user), { id: undefined })
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: 'User', id: id },
                    { type: 'Users' }
                ];
            }
        }),
        deleteUser: builder.mutation({
            query: function (id) { return ({
                url: "transmission/api/v1/users/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return [
                { type: 'User', id: id },
                { type: 'Users' }
            ]; }
        }),
        listMessages: builder.query({
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.size, size = _c === void 0 ? pageSize : _c, filter = _a.filter;
                return ({
                    url: "transmission/api/v1/messages?page=".concat(page, "&size=").concat(size, "&filter=").concat(filter),
                    responseHandler: responseHandler
                });
            },
            providesTags: ['Messages'],
            transformResponse: (transformPaginatedResponse)
        }),
        getMessage: builder.query({
            query: function (id) { return ({
                url: "transmission/api/v1/messages/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Message', id: id }]; }
        }),
        ackMessage: builder.mutation({
            query: function (id) { return ({
                url: "transmission/api/v1/messages/".concat(id),
                responseHandler: responseHandler,
                method: 'PUT'
            }); },
            invalidatesTags: function (result, error, id) { return [
                { type: 'Message', id: id },
                { type: 'Messages' }
            ]; }
        }),
        getIRTargets: builder.query({
            query: function (_a) {
                var page = _a.page, _b = _a.size, size = _b === void 0 ? 10 : _b;
                return ({
                    url: "/transmission/api/v1/ir-targets?page=".concat(page, "&size=").concat(size),
                    responseHandler: responseHandler
                });
            },
            providesTags: ['IRTargets'],
            transformResponse: (transformPaginatedResponse)
        }),
        saveIRTarget: builder.mutation({
            query: function (target) { return ({
                url: "/transmission/api/v1/ir-targets/".concat(target.id),
                method: 'PUT',
                body: __assign(__assign({}, target), { id: undefined })
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: 'IRTarget', id: id },
                    { type: 'IRTargets' }
                ];
            }
        }),
        addIRTarget: builder.mutation({
            query: function (target) { return ({
                url: "/transmission/api/v1/ir-targets",
                method: 'POST',
                body: __assign(__assign({}, target), { id: undefined })
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: 'IRTarget', id: id },
                    { type: 'IRTargets' }
                ];
            }
        }),
        deleteIRTarget: builder.mutation({
            query: function (id) { return ({
                url: "/transmission/api/v1/ir-targets/".concat(id),
                responseHandler: 'text',
                method: 'DELETE'
            }); },
            invalidatesTags: function () { return [{ type: 'IRTargets' }]; }
        }),
        getIRZones: builder.query({
            query: function () { return ({
                url: "/transmission/api/v1/ir-zones",
                responseHandler: responseHandler
            }); },
            providesTags: ['IRZone']
        }),
        saveIRZone: builder.mutation({
            query: function (target) { return ({
                url: "/transmission/api/v1/ir-zones/".concat(target.id),
                method: 'PUT',
                body: __assign(__assign({}, target), { id: undefined })
            }); },
            invalidatesTags: function () { return [{ type: 'IRZone' }]; }
        }),
        addIRZone: builder.mutation({
            query: function (target) { return ({
                url: "/transmission/api/v1/ir-zones",
                method: 'POST',
                body: __assign(__assign({}, target), { id: undefined })
            }); },
            invalidatesTags: function () { return [{ type: 'IRZone' }]; }
        }),
        deleteIRZone: builder.mutation({
            query: function (id) { return ({
                url: "/transmission/api/v1/ir-zones/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function () { return [{ type: 'IRZone' }]; }
        }),
        explainFrames: builder.mutation({
            query: function (_a) {
                var include = _a.include, source = _a.source;
                return ({
                    url: "transmission/api/v1/help/commands/explain?include=".concat(include),
                    headers: {
                        'content-type': 'text/plain'
                    },
                    responseHandler: responseHandlerText,
                    method: 'POST',
                    body: source
                });
            }
        }),
        explainRigado: builder.mutation({
            query: function (source) { return ({
                url: "transmission/api/v1/help/commands/rigado-logs",
                headers: {
                    'content-type': 'text/plain'
                },
                responseHandler: 'text',
                method: 'POST',
                body: source
            }); }
        }),
        explainRigadoZip: builder.mutation({
            query: function (_a) {
                var data = _a.data, include = _a.include;
                return ({
                    url: "transmission/api/v1/help/commands/rigado-logs.zip?include=".concat(include),
                    headers: {
                        'content-type': 'application/zip'
                    },
                    responseHandler: 'text',
                    method: 'POST',
                    body: data
                });
            }
        }),
        commandHelp: builder.query({
            query: function (type) { return ({
                url: "transmission/api/v1/help/commands/".concat(type),
                method: 'GET'
            }); }
        }),
        listLabelTypes: builder.query({
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.size, size = _c === void 0 ? 21 : _c, _d = _a.filter, filter = _d === void 0 ? '' : _d;
                return ({
                    url: "transmission/api/v1/label-types?page=".concat(page, "&size=").concat(size, "&filter=").concat(filter),
                    method: 'GET'
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: function () { return [{ type: 'LabelTypes' }]; }
        }),
        listLabelTypesVersions: builder.query({
            query: function (_a) {
                var plType = _a.plType, _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.size, size = _c === void 0 ? 20 : _c;
                return ({
                    url: "transmission/api/v1/label-types/".concat(plType, "?page=").concat(page, "&size=").concat(size),
                    method: 'GET'
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: function () { return [
                {
                    type: 'LabelTypes'
                }
            ]; }
        }),
        uploadLabelTypeImage: builder.mutation({
            query: function (_a) {
                var plType = _a.plType, image = _a.image;
                return ({
                    url: "transmission/api/v1/label-types/".concat(plType, "/image.png"),
                    responseHandler: 'text',
                    method: 'PUT',
                    headers: {
                        'content-type': 'image/png'
                    },
                    body: image
                });
            },
            invalidatesTags: function (result, error, _a) {
                var plType = _a.plType;
                return [
                    { type: 'LabelTypes', id: plType }
                ];
            }
        }),
        getLabelType: builder.query({
            query: function (_a) {
                var plType = _a.plType, hardwareVersion = _a.hardwareVersion, softwareVersion = _a.softwareVersion;
                return ({
                    url: "transmission/api/v1/label-types/".concat(plType, "/").concat(hardwareVersion, "/").concat(softwareVersion),
                    method: 'GET'
                });
            },
            providesTags: function (result, error, _a) {
                var plType = _a.plType, hardwareVersion = _a.hardwareVersion, softwareVersion = _a.softwareVersion;
                return [
                    {
                        type: 'LabelTypes',
                        id: "".concat(plType, "/").concat(hardwareVersion, "/").concat(softwareVersion)
                    }
                ];
            }
        }),
        saveLabelType: builder.mutation({
            query: function (label) { return ({
                url: "transmission/api/v1/label-types/".concat(label.plType, "/").concat(label.hardwareRevision, "/").concat(label.softwareRevision),
                method: 'PUT',
                body: __assign({}, label)
            }); },
            invalidatesTags: function () { return [{ type: 'LabelTypes' }]; }
        }),
        deleteLabelTypeVersion: builder.mutation({
            query: function (_a) {
                var plType = _a.plType, hardwareVersion = _a.hardwareVersion, softwareVersion = _a.softwareVersion;
                return ({
                    url: "transmission/api/v1/label-types/".concat(plType, "/").concat(hardwareVersion, "/").concat(softwareVersion),
                    method: 'DELETE',
                    responseHandler: 'text'
                });
            },
            invalidatesTags: function () { return [{ type: 'LabelTypes' }]; }
        }),
        deleteLabelType: builder.mutation({
            query: function (plType) { return ({
                url: "transmission/api/v1/label-types/".concat(plType),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function () { return [{ type: 'LabelTypes' }]; }
        }),
        sendLabelCommand: builder.mutation({
            query: function (command) { return ({
                url: "label/api/v1/stores/store/commands",
                method: 'POST',
                body: {
                    command: command
                }
            }); }
        })
    }); }
});
export var useGetUsersQuery = transmissionApi.useGetUsersQuery, useGetUserQuery = transmissionApi.useGetUserQuery, useSaveUserMutation = transmissionApi.useSaveUserMutation, useDeleteUserMutation = transmissionApi.useDeleteUserMutation, useListMessagesQuery = transmissionApi.useListMessagesQuery, useGetMessageQuery = transmissionApi.useGetMessageQuery, useAckMessageMutation = transmissionApi.useAckMessageMutation, useGetMetricsQuery = transmissionApi.useGetMetricsQuery, useCommandHelpQuery = transmissionApi.useCommandHelpQuery, useGetIRTargetsQuery = transmissionApi.useGetIRTargetsQuery, useSaveIRTargetMutation = transmissionApi.useSaveIRTargetMutation, useAddIRTargetMutation = transmissionApi.useAddIRTargetMutation, useDeleteIRTargetMutation = transmissionApi.useDeleteIRTargetMutation, useGetIRZonesQuery = transmissionApi.useGetIRZonesQuery, useSaveIRZoneMutation = transmissionApi.useSaveIRZoneMutation, useAddIRZoneMutation = transmissionApi.useAddIRZoneMutation, useDeleteIRZoneMutation = transmissionApi.useDeleteIRZoneMutation, useListLabelTypesQuery = transmissionApi.useListLabelTypesQuery, useListLabelTypesVersionsQuery = transmissionApi.useListLabelTypesVersionsQuery, useGetLabelTypeQuery = transmissionApi.useGetLabelTypeQuery, useDeleteLabelTypeMutation = transmissionApi.useDeleteLabelTypeMutation, useDeleteLabelTypeVersionMutation = transmissionApi.useDeleteLabelTypeVersionMutation, useUploadLabelTypeImageMutation = transmissionApi.useUploadLabelTypeImageMutation, useSaveLabelTypeMutation = transmissionApi.useSaveLabelTypeMutation, useExplainFramesMutation = transmissionApi.useExplainFramesMutation, useSendLabelCommandMutation = transmissionApi.useSendLabelCommandMutation, useExplainRigadoMutation = transmissionApi.useExplainRigadoMutation, useExplainRigadoZipMutation = transmissionApi.useExplainRigadoZipMutation, useGetQueuesQuery = transmissionApi.useGetQueuesQuery;
