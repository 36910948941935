import { jsx as _jsx } from "react/jsx-runtime";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ErrorIcon from '@mui/icons-material/Error';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
export function DeviceHistoryIcon(_a) {
    var event = _a.event;
    switch (event) {
        case 'url-updated':
            return _jsx(CloudDownloadIcon, {});
        case 'url-configured':
            return _jsx(EditNoteIcon, {});
        case 'auth-failed':
            return _jsx(NoEncryptionGmailerrorredIcon, {});
        case 'device-banned':
            return _jsx(ErrorIcon, {});
        case 'trusted':
            return _jsx(LocalPoliceIcon, {});
        case 'failed-to-update':
            return _jsx(WarningIcon, {});
        default:
            return _jsx(QuestionMarkIcon, {});
    }
}
