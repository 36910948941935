import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { transmissionApi, useGetQueuesQuery } from '../../../../api/api';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
export function QueueView() {
    var addError = useMessageHandler().addError;
    var dispatch = useAppDispatch();
    var _a = useGetQueuesQuery(), data = _a.data, isError = _a.isError, error = _a.error, isFetching = _a.isFetching;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch metrics: Unknown error');
        }
    }, [isError, addError, error]);
    var refreshApi = function () {
        dispatch(transmissionApi.util.invalidateTags(['Queues']));
    };
    var getQoS = function (s) {
        return s.includes('qos1') ? '1' : s.includes('qos2') ? '2' : '0';
    };
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch message", toolbar: _jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/tools" }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching })] }), children: _jsx(TableContainer, { children: _jsxs(Table, { sx: { minWidth: 650 }, "aria-label": "simple table", children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Name" }), _jsx(TableCell, { children: "QoS" }), _jsx(TableCell, { align: "right", children: "Total" }), _jsx(TableCell, { align: "right", children: "Ready" }), _jsx(TableCell, { align: "right", children: "Unacked" }), _jsx(TableCell, { align: "right", children: "State" }), _jsx(TableCell, { align: "right", children: "Idle since" })] }) }), _jsx(TableBody, { children: data &&
                            data.map(function (m) { return (_jsxs(TableRow, { sx: { '&:last-child td, &:last-child th': { border: 0 } }, children: [_jsx(TableCell, { component: "th", scope: "row", children: m.name
                                            .replace('mqtt-subscription-', '')
                                            .replace('qos1', '') }), _jsx(TableCell, { scope: "row", children: getQoS(m.name) }), _jsx(TableCell, { align: "right", children: m.messages }), _jsx(TableCell, { align: "right", children: m.messages }), _jsx(TableCell, { align: "right", children: m.messagesReady }), _jsx(TableCell, { align: "right", children: m.state }), _jsx(TableCell, { align: "right", children: m.idleSince })] }, m.name)); }) })] }) }) }));
}
