var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './helpers';
export var untrustedDeviceApi = createApi({
    reducerPath: 'untrustedDeviceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: ['UntrustedDevices', 'UntrustedDevice'],
    endpoints: function (builder) { return ({
        getDevices: builder.query({
            query: function (_a) {
                var page = _a.page;
                return ({
                    url: "infraconfig/api/v1/untrusted-devices?page=".concat(page),
                    responseHandler: responseHandler
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: ['UntrustedDevices']
        }),
        getDevice: builder.query({
            query: function (id) { return ({
                url: "infraconfig/api/v1/untrusted-devices/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Device', id: id }]; }
        }),
        trustDevice: builder.mutation({
            query: function (item) { return ({
                url: "infraconfig/api/v1/untrusted-devices/".concat(item.id),
                method: 'PUT',
                body: __assign(__assign({}, item), { id: undefined }),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: 'UntrustedDevice', id: id },
                    { type: 'UntrustedDevices' }
                ];
            }
        }),
        deleteDevice: builder.mutation({
            query: function (id) { return ({
                url: "infraconfig/api/v1/untrusted-devices/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return [
                { type: 'UntrustedDevice', id: id },
                { type: 'UntrustedDevices' }
            ]; }
        })
    }); }
});
export var useGetDeviceQuery = untrustedDeviceApi.useGetDeviceQuery, useGetDevicesQuery = untrustedDeviceApi.useGetDevicesQuery, useTrustDeviceMutation = untrustedDeviceApi.useTrustDeviceMutation, useDeleteDeviceMutation = untrustedDeviceApi.useDeleteDeviceMutation;
