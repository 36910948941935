import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect } from 'react';
import { DeleteButton } from '../../../../../../components/atoms/toolbar/DeleteButton';
export function KeyValuePair(_a) {
    var keyValue = _a.keyValue, value = _a.value, onChange = _a.onChange, onDelete = _a.onDelete;
    var _b = React.useState(keyValue), editKey = _b[0], setEditKey = _b[1];
    var _c = React.useState(value), editValue = _c[0], setEditValue = _c[1];
    useEffect(function () {
        setEditKey(keyValue);
        setEditValue(value);
    }, [keyValue, value]);
    var save = function () {
        if (editKey !== keyValue || editValue !== value) {
            onChange(editKey, editValue);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { size: 3, children: _jsx(TextField, { slotProps: {
                        htmlInput: { spellCheck: false, style: { fontFamily: 'monospace' } }
                    }, size: "small", value: editKey, onChange: function (e) { return setEditKey(e.target.value); }, onBlur: save }) }), _jsx(Grid, { size: 3, children: _jsx(TextField, { slotProps: {
                        htmlInput: { spellCheck: false, style: { fontFamily: 'monospace' } }
                    }, size: "small", value: editValue, onChange: function (e) { return setEditValue(e.target.value); }, onBlur: save }) }), _jsx(Grid, { size: 6, children: _jsx(DeleteButton, { onClick: onDelete }) })] }));
}
