import { jsx as _jsx } from "react/jsx-runtime";
import { Card, CardActionArea, CardHeader } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { RelativeDateText } from '../../../../../components/atoms/RelativeDateText';
export function FirmwareListItem(_a) {
    var product = _a.product, firmware = _a.firmware;
    var navigator = useNavigate();
    var title = "".concat(firmware.majorVersion, ".").concat(firmware.minorVersion, "-").concat(firmware.gitSha);
    return (_jsx(Card, { sx: { width: { sm: 1, md: 0.9 } }, children: _jsx(CardActionArea, { onClick: function () { return navigator("/infra/firmwares/".concat(product, "/").concat(firmware.id)); }, children: _jsx(CardHeader, { title: title, subheader: _jsx(RelativeDateText, { showBoth: true, date: firmware.timestamp, variant: 'subtitle1' }) }) }) }, firmware.id));
}
