import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import React, { useEffect } from 'react';
import { useGetFirmwareQuery } from '../../../../../api/firmwareApi';
import { ListItemInfo } from '../../../../../components/atoms/ListItemInfo';
import { useMessageHandler } from '../../../../../context/MessageContext';
export function FirmwareListItemInternal(_a) {
    var title = _a.title, firmwareId = _a.firmwareId, onEdit = _a.onEdit;
    var addError = useMessageHandler().addError;
    var _b = useGetFirmwareQuery(firmwareId), firmware = _b.data, isError = _b.isError, isFetching = _b.isFetching;
    useEffect(function () {
        if (isError && firmwareId) {
            addError("Failed to fetch firmware ".concat(firmwareId));
        }
    }, [isError, addError, firmwareId]);
    var version = firmware
        ? "".concat(firmware.majorVersion, ".").concat(firmware.minorVersion, "-").concat(firmware.gitSha)
        : 'Unknown';
    var text = isFetching ? 'Loading...' : version;
    return (_jsx(_Fragment, { children: _jsx(ListItemInfo, { title: title, icon: _jsx(PermDeviceInformationIcon, {}), value: text, onEdit: onEdit }) }));
}
