import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardActionArea, CardContent, CardHeader, CardMedia } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import Bse32 from '../../../../../common/assets/bse32.jpg';
import Bse32r from '../../../../../common/assets/bse32r.png';
import StoreLink from '../../../../../common/assets/storelink.png';
export function DeviceListItem(_a) {
    var device = _a.device, onClick = _a.onClick;
    var image = device.type === 'BSE32'
        ? Bse32
        : device.type === 'BSE32r'
            ? Bse32r
            : StoreLink;
    var title = device.title || 'Unnamed';
    return (_jsx(Grid, { size: { xs: 12, md: 4 }, children: _jsx(Card, { children: _jsxs(CardActionArea, { onClick: function () { return onClick(device); }, children: [_jsx(CardMedia, { sx: { height: 140 }, image: image }), _jsx(CardContent, { children: _jsx(CardHeader, { title: title, subheader: device.type }) })] }) }) }));
}
