var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { useMessageHandler } from '../../../../../../context/MessageContext';
import { KeyValuePair } from './KeyValuePair';
export function KeyValueList(_a) {
    var keyName = _a.keyName, value = _a.value, onChange = _a.onChange;
    var addError = useMessageHandler().addError;
    var _b = React.useState(''), newValue = _b[0], setNewValue = _b[1];
    var changeKey = function (oldKey, newKey, newValue) {
        var newKeyValue = value.map(function (kv) {
            if (kv.key === oldKey) {
                return { key: newKey, value: newValue };
            }
            return kv;
        });
        onChange(newKeyValue);
    };
    var addNew = function () {
        if (!newValue) {
            return;
        }
        if (value.find(function (kv) { return kv.key === newValue; })) {
            addError("Key ".concat(newValue, " already exists!"));
            return;
        }
        var newKeyValue = __spreadArray(__spreadArray([], value, true), [{ key: newValue, value: '' }], false);
        onChange(newKeyValue);
        setNewValue('');
    };
    var onDelete = function (key) {
        var newKeyValue = value.filter(function (kv) { return kv.key !== key; });
        onChange(newKeyValue);
    };
    return (_jsx(_Fragment, { children: _jsx(Stack, { direction: "column", spacing: 3, children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { size: 3, children: _jsx(Typography, { children: keyName }) }), _jsx(Grid, { size: 3, children: _jsx(Typography, { children: "Value" }) }), _jsx(Grid, { size: 6 }), value.map(function (pair) { return (_jsx(KeyValuePair, { keyValue: pair.key, value: pair.value, onChange: function (k, v) { return changeKey(pair.key, k, v); }, onDelete: function () { return onDelete(pair.key); } }, pair.key)); }), _jsx(TextField, { slotProps: {
                            htmlInput: {
                                spellCheck: false,
                                style: { fontFamily: 'monospace' }
                            }
                        }, size: "small", value: newValue, onChange: function (e) { return setNewValue(e.target.value); }, onBlur: addNew }), _jsx(Grid, { size: 9 })] }) }) }));
}
