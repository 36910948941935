var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { FormArea } from '../../../../../../components/atoms/form/FormArea';
import { PricerButton } from '../../../../../../components/atoms/PricerButton';
export function BatchUpdateDialog(_a) {
    var _this = this;
    var title = _a.title, labels = _a.labels, open = _a.open, onClose = _a.onClose, onExecute = _a.onExecute, children = _a.children;
    var _b = React.useState(false), busy = _b[0], setBusy = _b[1];
    var _c = React.useState(0), steps = _c[0], setSteps = _c[1];
    var _d = React.useState(0), step = _d[0], setStep = _d[1];
    var text = "The following labels will be targeted: ".concat(labels.map(function (l) { return l; }).join(', '));
    var wrapExecute = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setBusy(true);
                    setSteps(labels.length);
                    setStep(0);
                    return [4 /*yield*/, onExecute(function () { return setStep(function (step) { return step + 1; }); })];
                case 1:
                    _a.sent();
                    setBusy(false);
                    onClose();
                    setSteps(0);
                    setStep(0);
                    return [2 /*return*/];
            }
        });
    }); };
    var progress = steps > 0 ? (step / steps) * 100 : 0;
    return (_jsx(_Fragment, { children: _jsxs(Dialog, { open: open, children: [_jsxs(DialogTitle, { children: ["Batch ", title] }), _jsxs(DialogContent, { children: [_jsxs(FormArea, { children: [children && children, _jsx(Typography, { variant: "body1", children: text })] }), steps > 0 && (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Box, { sx: { width: '100%', mr: 1 }, children: _jsx(LinearProgress, { variant: "determinate", value: progress }) }), _jsx(Box, { sx: { minWidth: 35 }, children: _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: "".concat(Math.round(progress), "%") }) })] }))] }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: onClose, children: "Cancel" }), _jsx(PricerButton, { type: "ok", onClick: wrapExecute, busy: busy, children: "Execute" })] })] }) }));
}
