export var rgbColorList = {
    Green: [0, 3, 0],
    Red: [3, 0, 0],
    Blue: [0, 0, 3],
    Cyan: [0, 3, 3],
    Magenta: [3, 0, 3],
    Yellow: [3, 3, 0],
    White: [3, 3, 3]
};
export var greenColorList = { Green: [0, 3, 0] };
export var patterns = {
    random: 'Random pattern',
    'random-image': 'Random image',
    'horizontal-lines': 'Horizontal Lines',
    'vertical-lines': 'Vertical Lines',
    'grid-black': 'Black grid pattern',
    'grid-yellow': 'Yellow grid pattern',
    'grid-red': 'Red grid pattern',
    'checker-board-tiny': 'Checker board (Tiny)',
    'checker-board-small': 'Checker board (small)',
    'checker-board-medium': 'Checker board (medium)',
    'checker-board-large': 'Checker board (large)',
    'checker-board-huge': 'Checker board (huge)',
    'random-color': 'Random color',
    'single-color-black': 'Single color black',
    'single-color-white': 'Single color white',
    'single-color-red': 'Single color red',
    'single-color-yellow': 'Single color yellow',
    're-upload': 'Re-upload same image'
};
export var getPricerLabelId = function (barcode) {
    var msd = +barcode.substring(2, 7);
    var lsd = +barcode.substring(7, 12);
    return msd * 65536 + lsd;
};
export var getPricerLabelType = function (barcode) {
    return +barcode.substring(12, 16);
};
