import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
export function Filter(_a) {
    var filter = _a.filter, setFilter = _a.setFilter, _b = _a.title, title = _b === void 0 ? 'Filter' : _b, valuePrefix = _a.valuePrefix;
    var value = valuePrefix && filter.startsWith(valuePrefix)
        ? filter.slice(valuePrefix.length)
        : filter;
    var doSetFilter = function (value) {
        setFilter(valuePrefix ? valuePrefix + value : value);
    };
    return (_jsxs(FormControl, { variant: "outlined", size: "small", children: [_jsx(InputLabel, { htmlFor: "filter", children: title }), _jsx(OutlinedInput, { id: "filter", label: title, value: value, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return doSetFilter(''); }, edge: "end", children: _jsx(ClearIcon, {}) }) }), onChange: function (e) { return doSetFilter(e.target.value); } })] }));
}
