import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { EditAccessPointDialog } from '../atoms/EditAccessPointDialog';
import { EditNetworkDialog } from '../atoms/EditNetworkDialog';
import { EditServerDialog } from '../atoms/EditServerDialog';
import { AccessPointConfiguration } from './AccessPointConfiguration';
import { AccessPointDetails } from './AccessPointDetails';
import { EditTransceivers } from './EditTransceivers';
import { OnlineAccessPointDetails } from './OnlineAccessPointDetails';
export function AccessPointView(_a) {
    var accessPoint = _a.accessPoint;
    var _b = React.useState(false), edit = _b[0], setEdit = _b[1];
    var _c = React.useState(false), editNetwork = _c[0], setEditNetwork = _c[1];
    var _d = React.useState(false), editServer = _d[0], setEditServer = _d[1];
    return (_jsxs(_Fragment, { children: [_jsx(EditAccessPointDialog, { accessPoint: accessPoint, open: edit, onClose: function () { return setEdit(false); } }), _jsx(EditServerDialog, { accessPoint: accessPoint, open: editServer, onClose: function () { return setEditServer(false); } }), _jsx(EditNetworkDialog, { accessPoint: accessPoint, open: editNetwork, onClose: function () { return setEditNetwork(false); } }), _jsx(AccessPointDetails, { accessPoint: accessPoint, onClickEditName: function () { return setEdit(true); }, onClickEditZone: function () { return setEdit(true); } }), accessPoint.type !== 'radio' && (_jsx(AccessPointConfiguration, { accessPoint: accessPoint, onEditNetwork: function () { return setEditNetwork(true); }, onEditServer: function () { return setEditServer(true); } })), accessPoint.online && accessPoint.type !== 'radio' && (_jsx(OnlineAccessPointDetails, { accessPoint: accessPoint })), _jsx(EditTransceivers, { accessPoint: accessPoint })] }));
}
