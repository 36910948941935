var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import jsyaml from 'js-yaml';
export var emptyMetadata = {
    driver: '',
    state: '',
    last_updated: '',
    comment: ''
};
export var emptyConfig = {
    udd: {
        engine_version: '',
        package_id: '',
        package_version: ''
    },
    display_config: {
        busy_pin_is_inverted: false,
        spi_cs_byte_toggle_enabled: false,
        spi_mode: '',
        spi_clk_speed: '',
        scan_type: ''
    },
    temperature_config: {
        format: '',
        minimum: 0,
        maximum: 0
    }
};
var cleanUpData = function (data) {
    if (typeof data === 'number') {
        return '' + data;
    }
    return data ? data.split('\n').join(', ') : undefined;
};
var cleanUpStepCommand = function (step) {
    var data = cleanUpData(step.data);
    var command = '';
    if (data && step.command) {
        command = step.command + ', ' + data;
    }
    else if (step.command) {
        command = step.command;
    }
    else if (data) {
        command = "send ".concat(data);
    }
    else {
        command = '# Invalid step';
    }
    return command.replace('send-command', 'send').split('\n').join(', ');
};
var cleanUpStep = function (step) {
    return {
        name: step.name,
        steps: step.steps.map(function (s) { return cleanUpStepCommand(s); }).join('\n'),
        if: step['if']
    };
};
export var importUdd = function (text) {
    var data = jsyaml.load(text);
    if (!data) {
        throw new Error('Invalid YAML data');
    }
    if (!data.sequence ||
        !data.sequence.chunks ||
        !Array.isArray(data.sequence.chunks)) {
        throw new Error('Invalid YAML (invalid sequence.chunks)');
    }
    if (!data.constants || !data.constants) {
        throw new Error('Invalid YAML (invalid constants)');
    }
    if (!data.constants || !data.constants) {
        throw new Error('Invalid YAML (invalid constants)');
    }
    return {
        sequence: data.sequence.chunks.map(function (step) {
            return cleanUpStep(step);
        }),
        constants: Object.keys(data.constants).map(function (key) { return ({
            key: key,
            value: data.constants[key]
        }); }),
        commands: Object.keys(data.commands).map(function (key) { return ({
            key: key,
            value: data.commands[key]
        }); }),
        metadata: data.metadata,
        config: {
            udd: data.udd,
            display_config: data.display_config,
            temperature_config: data.temperature_config
        }
    };
};
export var exportUdd = function (config) {
    var _a, _b, _c;
    var data = {
        sequence: {
            chunks: config.sequence.map(function (step) { return (__assign(__assign({}, step), { steps: step.steps
                    .split('\n')
                    .map(function (s) { return ({ command: s.replace('send ', 'send-command ') }); }) })); })
        },
        constants: Object.assign.apply(Object, __spreadArray([{}], config.constants.map(function (kvp) {
            var _a;
            return (_a = {}, _a[kvp.key] = kvp.value, _a);
        }), false)),
        commands: Object.assign.apply(Object, __spreadArray([{}], config.commands.map(function (kvp) {
            var _a;
            return (_a = {}, _a[kvp.key] = kvp.value, _a);
        }), false)),
        metadata: config.metadata,
        udd: (_a = config.config) === null || _a === void 0 ? void 0 : _a.udd,
        display_config: (_b = config.config) === null || _b === void 0 ? void 0 : _b.display_config,
        temperature_config: (_c = config.config) === null || _c === void 0 ? void 0 : _c.temperature_config
    };
    return jsyaml.dump(data);
};
