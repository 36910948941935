import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommentIcon from '@mui/icons-material/Comment';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import StraightenIcon from '@mui/icons-material/Straighten';
import { List, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetFirmwareQuery } from '../../../../../api/firmwareApi';
import { getHumanFriendlySize } from '../../../../../common/helpers';
import { ListItemInfo } from '../../../../../components/atoms/ListItemInfo';
import { RelativeDateText } from '../../../../../components/atoms/RelativeDateText';
import { useMessageHandler } from '../../../../../context/MessageContext';
export function FirmwareDetails(_a) {
    var id = _a.id;
    var addError = useMessageHandler().addError;
    var _b = useGetFirmwareQuery(id || ''), firmware = _b.data, isError = _b.isError, isFetching = _b.isFetching;
    useEffect(function () {
        if (isError) {
            addError("Failed to fetch ".concat(id));
        }
    }, [isError, addError, id]);
    var friendly = getHumanFriendlySize((firmware === null || firmware === void 0 ? void 0 : firmware.size) || 0);
    var size = "".concat(friendly, " (").concat(firmware === null || firmware === void 0 ? void 0 : firmware.size, ")");
    if (isFetching) {
        return _jsx(_Fragment, { children: "Loading..." });
    }
    return (_jsxs(List, { children: [_jsx(ListItemInfo, { title: "File", icon: _jsx(AttachFileIcon, {}), value: firmware === null || firmware === void 0 ? void 0 : firmware.filename }), _jsx(ListItemInfo, { title: "Size", icon: _jsx(StraightenIcon, {}), value: size }), _jsx(ListItemInfo, { title: "Added", icon: _jsx(QueryBuilderIcon, {}), value: _jsx(RelativeDateText, { showBoth: true, date: firmware === null || firmware === void 0 ? void 0 : firmware.timestamp }) }), _jsx(ListItemInfo, { title: "Changes", icon: _jsx(CommentIcon, {}), value: _jsx(Typography, { fontFamily: 'Roboto Mono', sx: { whiteSpace: 'pre-line' }, children: firmware === null || firmware === void 0 ? void 0 : firmware.changeLog }) })] }));
}
