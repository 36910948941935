import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CircularProgress } from '@mui/material';
import React from 'react';
var getDefaultTitle = function (type) {
    switch (type) {
        case 'ok':
            return 'Ok';
        case 'cancel':
            return 'Cancel';
        default:
            return 'Do Something!';
    }
};
export function PricerButton(_a) {
    var _b = _a.type, type = _b === void 0 ? 'other' : _b, rawTitle = _a.title, _c = _a.disabled, disabled = _c === void 0 ? false : _c, children = _a.children, onClick = _a.onClick, _d = _a.busy, busy = _d === void 0 ? false : _d;
    var title = rawTitle || getDefaultTitle(type);
    var content = _jsx(_Fragment, { children: (children && children) || title });
    if (busy) {
        return (_jsxs(Button, { variant: "contained", disabled: true, children: [_jsx(CircularProgress, { size: "1em" }), content] }));
    }
    if (type === 'ok') {
        return (_jsx(Button, { variant: "contained", onClick: onClick, disabled: disabled, children: content }));
    }
    if (type === 'cancel') {
        return (_jsx(Button, { onClick: onClick, variant: "text", color: "error", disabled: disabled, children: content }));
    }
    if (type === 'dangerous') {
        return (_jsx(Button, { onClick: onClick, variant: "text", color: "error", disabled: disabled, children: content }));
    }
    return (_jsx(Button, { variant: "outlined", onClick: onClick, disabled: disabled, children: content }));
}
