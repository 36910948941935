import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useListAccessPointsQuery } from '../../api/accessPointApi';
import { useGetIRTargetsQuery } from '../../api/api';
import { useMessageHandler } from '../../context/MessageContext';
import { useZoneContext } from '../../context/ZoneContext';
export function IRTargetList(_a) {
    var label = _a.label, accessPoint = _a.accessPoint, setAccessPoint = _a.setAccessPoint, _b = _a.title, title = _b === void 0 ? 'Target' : _b, _c = _a.removeAuto, removeAuto = _c === void 0 ? false : _c;
    var zoneId = useZoneContext().zoneId;
    var _d = useListAccessPointsQuery({ page: 0, size: 100, zoneId: zoneId }), allAccessPoints = _d.data, fetchAccessPointsError = _d.isError;
    var _e = useGetIRTargetsQuery({ page: 0, size: 100 }), allTargets = _e.data, fetchTargetsError = _e.isError;
    var addError = useMessageHandler().addError;
    useEffect(function () {
        if (fetchTargetsError || fetchAccessPointsError) {
            addError('Failed to fetch data');
        }
    }, [fetchTargetsError, fetchAccessPointsError, addError]);
    var accessPoints = (allAccessPoints === null || allAccessPoints === void 0 ? void 0 : allAccessPoints.content) || [];
    var targets = ((allTargets === null || allTargets === void 0 ? void 0 : allTargets.content) || [])
        .map(function (t) {
        var _a;
        return ({
            id: t.id,
            found: ((_a = label === null || label === void 0 ? void 0 : label.irTargets) === null || _a === void 0 ? void 0 : _a.includes(t.id)) || false,
            ap: accessPoints.find(function (ap) { return ap.id === t.accessPoint; }),
            title: t.title
        });
    })
        .filter(function (t) { return t.ap; });
    var found = targets.filter(function (t) { return t.found; });
    var unknown = targets.filter(function (t) { return !t.found; });
    return (_jsxs(FormControl, { variant: "standard", sx: { display: { xs: 'none', sm: 'block' } }, children: [_jsx(InputLabel, { id: "page-label", children: title }), _jsxs(Select, { sx: { maxWidth: 400, minWidth: { md: 200 } }, labelId: "page-label", value: accessPoint, label: title, onChange: function (e) { return setAccessPoint(e.target.value); }, children: [!removeAuto && _jsx(MenuItem, { value: "auto", children: "Auto" }), found.length > 0 && (_jsx(ListSubheader, { children: "Located Access-points" })), found.map(function (t) {
                        var _a;
                        return (_jsx(MenuItem, { value: t.id, children: _jsxs(_Fragment, { children: [(_a = t.ap) === null || _a === void 0 ? void 0 : _a.name, " - ", t.title] }) }, t.id));
                    }), _jsx(ListSubheader, { children: "All Access-points" }), !removeAuto && (_jsx(MenuItem, { value: "ir", children: "Force (ir)" }, "ir")), !removeAuto && (_jsx(MenuItem, { value: "radio", children: "Force (radio)" }, "radio")), unknown.map(function (t) {
                        var _a;
                        return (_jsx(MenuItem, { value: t.id, children: _jsxs(_Fragment, { children: [(_a = t.ap) === null || _a === void 0 ? void 0 : _a.name, " - ", t.title] }) }, t.id));
                    })] })] }));
}
