var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { deviceHistoryApi, useGetHistoriesQuery } from '../../../../../api/deviceHistoryApi';
import { BackButton } from '../../../../../components/atoms/toolbar/BackButton';
import { Filter } from '../../../../../components/atoms/toolbar/Filter';
import { RefreshButton } from '../../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../../context/MessageContext';
import { useAppDispatch } from '../../../../../store/store';
import { ListItemView } from '../organisms/ListItemView';
export function LogListView() {
    var _this = this;
    var dispatch = useAppDispatch();
    var addRetryableError = useMessageHandler().addRetryableError;
    var _a = React.useState(0), page = _a[0], setPage = _a[1];
    var _b = React.useState(''), filter = _b[0], setFilter = _b[1];
    var refreshApi = useCallback(function () {
        dispatch(deviceHistoryApi.util.invalidateTags(['Histories', 'History']));
    }, [dispatch]);
    var _c = useGetHistoriesQuery({ page: page, filter: filter }), logs = _c.data, isFetching = _c.isFetching, isError = _c.isError;
    useEffect(function () {
        if (isError) {
            addRetryableError('Failed to fetch logs', function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, refreshApi()];
            }); }); });
        }
    }, [isError, addRetryableError, refreshApi]);
    return (_jsx(SimplePageContent, { isError: isError, isFetching: isFetching, isEmpty: !logs || logs.content.length === 0, emptyMessage: "No logs found", errorMessage: "Failed to fetch logs", hasPaper: false, toolbar: _jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/infra" }), _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsx(Filter, { filter: filter, setFilter: setFilter }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching })] }), children: logs && _jsx(ListItemView, { page: page, setPage: setPage, logs: logs }) }));
}
