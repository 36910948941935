import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useGetIRZonesQuery } from '../../api/api';
import { useMessageHandler } from '../../context/MessageContext';
export function ZoneTitle(_a) {
    var _b;
    var zoneId = _a.zoneId;
    var addError = useMessageHandler().addError;
    var _c = useGetIRZonesQuery(), irZones = _c.data, failedToFetchZones = _c.isError;
    useEffect(function () {
        if (failedToFetchZones) {
            addError('Failed to fetch ir zones');
        }
    }, [failedToFetchZones, addError]);
    var title = (_b = irZones === null || irZones === void 0 ? void 0 : irZones.find(function (zone) { return zone.id === zoneId; })) === null || _b === void 0 ? void 0 : _b.title;
    return _jsx(_Fragment, { children: title });
}
