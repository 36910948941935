import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetFirmwaresQuery } from '../../../../api/firmwareApi';
import { SelectBox } from '../../../../components/atoms/form/SelectBox';
import { useMessageHandler } from '../../../../context/MessageContext';
export function FirmwareSelector(_a) {
    var product = _a.product, firmwareId = _a.firmwareId, setFirmware = _a.setFirmware, _b = _a.emptyTitle, emptyTitle = _b === void 0 ? 'None' : _b;
    var addApiError = useMessageHandler().addApiError;
    // TODO: How do we deal with pagination here?
    var _c = useGetFirmwaresQuery({
        product: product || '',
        page: 0
    }), firmwares = _c.data, isError = _c.isError;
    useEffect(function () {
        if (isError) {
            addApiError('Failed to fetch devices');
        }
    }, [isError, addApiError]);
    var fwlist = ((firmwares === null || firmwares === void 0 ? void 0 : firmwares.content) || []).map(function (f) { return ({
        id: f.id,
        version: "".concat(f.majorVersion, ".").concat(f.minorVersion, "-").concat(f.gitSha)
    }); });
    var listId = firmwareId || '$$NONE$$';
    var setId = function (id) { return setFirmware(id === '$$NONE$$' ? undefined : id); };
    return (_jsxs(SelectBox, { title: "Firmware", value: listId, setValue: setId, children: [_jsx(MenuItem, { value: "$$NONE$$", children: emptyTitle }), fwlist.map(function (f) { return (_jsx(MenuItem, { value: f.id, children: f.version }, f.id)); })] }));
}
