import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { List, ListItem, ListItemText, Stack } from '@mui/material';
import React from 'react';
import { useGetDevicesQuery } from '../../../../../api/deviceApi';
import { PricerPagination } from '../../../../../components/atoms/PricerPagination';
import { RelativeDateText } from '../../../../../components/atoms/RelativeDateText';
export function DeviceList(_a) {
    var filter = _a.filter;
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    var _c = useGetDevicesQuery({
        filter: filter,
        page: page
    }), messages = _c.data, isFetching = _c.isFetching, isError = _c.isError;
    return (_jsx(_Fragment, { children: messages && !isFetching && !isError && (_jsxs(Stack, { direction: "column", alignItems: "center", children: [_jsx(List, { sx: { width: { xs: 1, md: 0.9 } }, children: messages.content.map(function (d) { return (_jsx(ListItem, { children: _jsx(ListItemText, { primary: d.title, secondary: _jsx(RelativeDateText, { showBoth: true, date: d.lastSeen, variant: 'subtitle1' }) }) }, d.id)); }) }), _jsx(PricerPagination, { items: messages, page: page, setPage: setPage })] })) }));
}
