import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AppsIcon from '@mui/icons-material/Apps';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TitleIcon from '@mui/icons-material/Title';
import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@mui/material';
import React from 'react';
import { relativeTime } from '../../../../common/helpers';
import { ListItemInfo } from '../../../../components/atoms/ListItemInfo';
import { ZoneTitle } from '../../../../components/atoms/ZoneTitle';
export function AccessPointDetails(_a) {
    var _b, _c;
    var accessPoint = _a.accessPoint, onClickEditName = _a.onClickEditName, onClickEditZone = _a.onClickEditZone;
    var mask = "TX: ".concat(accessPoint.transmitMaskLimit, " RX: ").concat(accessPoint.receiveMaskLimit, ", TX power: ").concat(accessPoint.transmitPower);
    var expiry = (_b = accessPoint.client) === null || _b === void 0 ? void 0 : _b.certExpiry;
    return (_jsxs(Accordion, { defaultExpanded: true, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "details-content", id: "details-header", children: _jsx(Typography, { children: "Details" }) }), _jsx(AccordionDetails, { children: _jsxs(List, { children: [_jsx(ListItemInfo, { title: "Name", icon: _jsx(TitleIcon, {}), value: accessPoint.name, onEdit: onClickEditName }), _jsx(ListItemInfo, { title: "Zone", icon: _jsx(AppsIcon, {}), value: _jsx(ZoneTitle, { zoneId: accessPoint.zoneId }), onEdit: onClickEditZone }), _jsx(ListItemInfo, { title: "Type", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.type }), _jsx(ListItemInfo, { title: "Model", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.model }), _jsx(ListItemInfo, { title: "Hardware ID", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.hardwareId }), _jsx(ListItemInfo, { title: "Software ID", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.softwareId }), _jsx(ListItemInfo, { title: "Online", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.online ? 'Online' : 'Offline' }), accessPoint.online && (_jsx(ListItemInfo, { title: "Connected from", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.source })), _jsx(ListItemInfo, { title: "Last seen", icon: _jsx(DeveloperBoardIcon, {}), value: relativeTime(accessPoint.seen) }), accessPoint.online && (_jsx(ListItemInfo, { title: "Mode", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.mode })), accessPoint.online && (_jsx(ListItemInfo, { title: "RX/TX Mask limit", icon: _jsx(DeveloperBoardIcon, {}), value: mask })), expiry && (_jsx(ListItemInfo, { title: "Certificate expiry", icon: _jsx(DeveloperBoardIcon, {}), value: relativeTime(expiry) })), ((_c = accessPoint.client) === null || _c === void 0 ? void 0 : _c.fingerprint) && (_jsx(ListItemInfo, { title: "Fingerprint", icon: _jsx(DeveloperBoardIcon, {}), value: accessPoint.client.fingerprint }))] }) })] }));
}
