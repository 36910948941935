import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ArchiveIcon from '@mui/icons-material/Archive';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ImageIcon from '@mui/icons-material/Image';
import MemoryIcon from '@mui/icons-material/Memory';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { TextField } from '@mui/material';
import React from 'react';
import { transmissionApi } from '../../../../api/api';
import { DropDownMenu } from '../../../../components/atoms/toolbar/DropDownMenu';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { useAppDispatch } from '../../../../store/store';
export function MessageToolbar(_a) {
    var isFetching = _a.isFetching, filter = _a.filter, onSetFilter = _a.onSetFilter;
    var dispatch = useAppDispatch();
    var onRefresh = function () {
        dispatch(transmissionApi.util.invalidateTags(['Messages', 'Message']));
    };
    var typeMenu = [
        { title: 'Filter on type', value: '', icon: _jsx(FilterAltOffIcon, {}) },
        { title: 'Command', value: 'type:command', icon: _jsx(TaskAltIcon, {}) },
        { title: 'Write', value: 'type:write', icon: _jsx(ImageIcon, {}) },
        { title: 'WriteObject', value: 'type:writeObject', icon: _jsx(MemoryIcon, {}) },
        { title: 'Info', value: 'type:info', icon: _jsx(PermDeviceInformationIcon, {}) },
        { title: 'Event', value: 'type:event', icon: _jsx(ArchiveIcon, {}) },
        { title: 'Assignment', value: 'type:assignment', icon: _jsx(AltRouteIcon, {}) }
    ];
    var typeFilter = filter && filter.startsWith('type:') ? filter : '';
    var statusMenu = [
        { title: 'Filter on status', value: '', icon: _jsx(FilterAltOffIcon, {}) },
        { title: 'Error', value: 'status:error', icon: _jsx(ErrorIcon, {}) },
        { title: 'Pending', value: 'status:pending', icon: _jsx(PendingActionsIcon, {}) },
        { title: 'Success', value: 'status:success', icon: _jsx(DoneIcon, {}) }
    ];
    var statusFilter = filter && filter.startsWith('status:') ? filter : '';
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { sx: { display: { xs: 'none', sm: 'none', md: 'block' } }, label: "Barcode", variant: "outlined", size: "small", value: filter.startsWith('barcode:') ? filter.split(':')[1] : '', onChange: function (e) { return onSetFilter("barcode:".concat(e.target.value)); } }), _jsx(DropDownMenu, { title: "Grid-size", menu: typeMenu, value: typeFilter, onChange: onSetFilter }), _jsx(DropDownMenu, { title: "Grid-size", menu: statusMenu, value: statusFilter, onChange: onSetFilter }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: onRefresh, isFetching: isFetching })] }));
}
