import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
var ZoneContext = React.createContext({});
export var useZoneContext = function () {
    return useContext(ZoneContext);
};
export var ZoneContextProvider = function (_a) {
    var children = _a.children;
    var _b = useLocalStorage('selected.zone', ''), zoneId = _b[0], setZoneId = _b[1];
    var _c = useState(''), zoneTitle = _c[0], setZoneTitle = _c[1];
    var value = useMemo(function () { return ({
        zoneId: zoneId,
        zoneTitle: zoneTitle,
        setZoneId: setZoneId,
        setZoneTitle: setZoneTitle
    }); }, [zoneId, zoneTitle, setZoneId, setZoneTitle]);
    return _jsx(ZoneContext.Provider, { value: value, children: children });
};
