import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Card, CardActionArea, CardHeader, CardMedia, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { getConfig } from '../../../../../common/config';
import { relativeTime } from '../../../../../common/helpers';
import { useAuthentication } from '../../../../../common/hooks/authentication';
export function LabelListItem(_a) {
    var label = _a.label, onClick = _a.onClick, selected = _a.selected, onSelect = _a.onSelect, hasSelection = _a.hasSelection;
    var token = useAuthentication().token;
    var _b = React.useState(false), showCheck = _b[0], setShowCheck = _b[1];
    var url = "".concat(getConfig().apiBase, "/transmission/api/v1/labels/").concat(label.barcode, "/image/").concat(0, "?access_token=").concat(token, "&updated=").concat(label.lastSeen);
    var date = function (date) {
        return date === undefined ? 'Never' : relativeTime(date);
    };
    var hasTitle = label.title && label.title.length > 0;
    var title = hasTitle ? label.title : label.barcode;
    var subheader = hasTitle
        ? "".concat(date(label === null || label === void 0 ? void 0 : label.lastSeen), " - ").concat(label.barcode)
        : date(label === null || label === void 0 ? void 0 : label.lastSeen);
    var isTouch = 'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;
    var handleClick = function (event) {
        onSelect(label, event.shiftKey);
        event.stopPropagation();
    };
    var handleClickCard = function (event) {
        onClick(label, event.shiftKey);
        event.stopPropagation();
    };
    var onMouseEnter = function () {
        setShowCheck(true);
    };
    var onMouseLeave = function () {
        setShowCheck(false);
    };
    var action = hasSelection || isTouch || showCheck ? (_jsxs(IconButton, { "aria-label": "select", onClick: handleClick, children: [!selected && _jsx(CheckBoxOutlineBlankIcon, {}), selected && _jsx(CheckBoxIcon, {})] })) : null;
    return (_jsx(Grid, { size: { xs: 12, md: 4 }, children: _jsx(Card, { children: _jsxs(CardActionArea, { component: "div", onClick: handleClickCard, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, children: [_jsx(CardHeader, { title: title, subheader: subheader, action: action, titleTypographyProps: {
                            noWrap: true,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        } }), _jsx(CardMedia, { component: "img", sx: { objectFit: 'scale-down', width: 1, height: '100px' }, image: url })] }) }) }, label.barcode));
}
