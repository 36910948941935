import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useNavigate } from 'react-router';
import PricerLogo from '../../../common/assets/pricer-logo.svg';
import { ZoneMenu } from '../../atoms/ZoneMenu';
import { IRBaseStation } from './atoms/IRBaseStation';
import { PendingMessages } from './atoms/PendingMessages';
import ProfileMenu from './atoms/ProfileMenu';
export function TopMenu() {
    var navigator = useNavigate();
    var goto = function (whereTo) {
        if (whereTo !== null) {
            navigator(whereTo);
        }
    };
    return (_jsx(AppBar, { position: "relative", elevation: 0, children: _jsxs(Toolbar, { children: [_jsx(Link, { onClick: function () { return goto('/'); }, children: _jsx(PricerLogo, { style: { minWidth: '104px' } }) }), _jsx("div", { style: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }, children: _jsxs(Stack, { direction: "row", sx: { alignItems: 'center' }, spacing: 1, children: [_jsx(ZoneMenu, {}), _jsx(IRBaseStation, {}), _jsx(PendingMessages, {}), _jsx(ProfileMenu, {})] }) })] }) }));
}
