var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { emptyMetadata } from '../ConfigProcessor';
export function MetadataEditor(_a) {
    var _b = _a.value, value = _b === void 0 ? emptyMetadata : _b, setValue = _a.setValue;
    return (_jsx(Stack, { direction: "column", spacing: 3, children: _jsxs(Grid, { container: true, spacing: 1, children: [_jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Driver:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: value.driver, onChange: function (e) { return setValue(__assign(__assign({}, value), { driver: e.target.value })); } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "State:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: value === null || value === void 0 ? void 0 : value.state, onChange: function (e) { return setValue(__assign(__assign({}, value), { state: e.target.value })); } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Last updated:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, value: value === null || value === void 0 ? void 0 : value.last_updated, onChange: function (e) {
                            return setValue(__assign(__assign({}, value), { last_updated: e.target.value }));
                        } }) }), _jsx(Grid, { size: 2, children: _jsx(Typography, { children: "Comment:" }) }), _jsx(Grid, { size: 10, children: _jsx(TextField, { size: "small", fullWidth: true, multiline: true, rows: 3, value: value === null || value === void 0 ? void 0 : value.comment, onChange: function (e) { return setValue(__assign(__assign({}, value), { comment: e.target.value })); } }) })] }) }));
}
