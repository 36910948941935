var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import CodeIcon from '@mui/icons-material/Code';
import { Box, Fab, Stack } from '@mui/material';
import React from 'react';
import { ChunkEditor } from '../atoms/ChunkEditor';
import { UDDAccordion } from '../atoms/UDDAccordion';
export function ChunkList(_a) {
    var sequenceOffset = _a.sequenceOffset, sequence = _a.sequence, setSequence = _a.setSequence, expanded = _a.expanded, setExpanded = _a.setExpanded;
    var onMoveChunkUp = function (index) {
        if (index < 1) {
            return;
        }
        var newSequence = __spreadArray([], sequence, true);
        var tmp = newSequence[index - 1];
        newSequence[index - 1] = newSequence[index];
        newSequence[index] = tmp;
        setSequence(newSequence);
        setExpanded(index + sequenceOffset - 1);
    };
    var onMoveChunkDown = function (index) {
        if (index >= sequence.length - 1) {
            return;
        }
        var newSequence = __spreadArray([], sequence, true);
        var tmp = newSequence[index + 1];
        newSequence[index + 1] = newSequence[index];
        newSequence[index] = tmp;
        setSequence(newSequence);
        setExpanded(index + sequenceOffset + 1);
    };
    var setStepName = function (index, name) {
        var newSequence = __spreadArray([], sequence, true);
        newSequence[index].name = name;
        console.log(index, newSequence, name);
        setSequence(newSequence);
    };
    var addSequence = function () {
        setSequence(__spreadArray(__spreadArray([], sequence, true), [{ name: 'New step', steps: '', if: '' }], false));
        setExpanded(sequence.length + sequenceOffset);
    };
    var removeSequence = function (index) {
        var newSequence = __spreadArray([], sequence, true);
        newSequence.splice(index, 1);
        setSequence(newSequence);
    };
    var changeSteps = function (index, steps) {
        var newSequence = __spreadArray([], sequence, true);
        newSequence[index].steps = steps;
        setSequence(newSequence);
    };
    var changeConditional = function (index, conditional) {
        var newSequence = __spreadArray([], sequence, true);
        newSequence[index].if = conditional;
        setSequence(newSequence);
    };
    var getIcon = function (step) {
        return step.if ? _jsx(AltRouteIcon, {}) : _jsx(CodeIcon, {});
    };
    return (_jsxs(_Fragment, { children: [sequence.map(function (step, index) { return (_jsx(UDDAccordion, { index: sequenceOffset + index, expanded: expanded, setExpanded: setExpanded, title: step.name, icon: getIcon(step), children: _jsx(ChunkEditor, { index: index, lastIndex: sequence.length, name: step.name, steps: step.steps, onChangeSteps: function (index, steps) { return changeSteps(index, steps); }, conditional: step.if, onMoveUp: onMoveChunkUp, onMoveDown: onMoveChunkDown, onSaveName: function (index, name) { return setStepName(index, name); }, onConditionalChange: function (index, conditional) {
                        return changeConditional(index, conditional);
                    }, onRemove: function (index) { return removeSequence(index); } }) }, index)); }), _jsxs(Stack, { sx: { justifyContent: 'space-between' }, direction: "row", m: 3, children: [_jsx(Box, {}), _jsx(Fab, { color: "primary", "aria-label": "add", onClick: addSequence, children: _jsx(AddIcon, {}) })] })] }));
}
