import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { usePopupMenu } from './PopupMenu';
export function PopupMenuItem(_a) {
    var children = _a.children, onClick = _a.onClick, onSelect = _a.onSelect, path = _a.path, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var navigator = useNavigate();
    var onCloseMenu = usePopupMenu().onCloseMenu;
    var handleClick = function () {
        onCloseMenu();
        if (onClick) {
            onClick();
        }
        if (onSelect) {
            onSelect(true);
        }
        if (path) {
            navigator(path);
        }
    };
    return (_jsx(MenuItem, { disabled: disabled, onClick: handleClick, children: children }));
}
