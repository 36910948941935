var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Divider, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { getConfig } from '../../../../common/config';
import { useAuthentication } from '../../../../common/hooks/authentication';
import { PricerPagination } from '../../../../components/atoms/PricerPagination';
import { PopupMenu } from '../../../../components/atoms/toolbar/PopupMenu';
import { PopupMenuItem } from '../../../../components/atoms/toolbar/PopupMenuItem';
import { LabelListItem } from '../ManageLabelView/atoms/LabelListItem';
import { BatchFirmwareUpgradeDialog } from '../ManageLabelView/Batch/molecules/BatchFirmwareUpgradeDialog';
import { BatchFlashDialog } from '../ManageLabelView/Batch/molecules/BatchFlashDialog';
import { BatchLocateDialog } from '../ManageLabelView/Batch/molecules/BatchLocateDialog';
import { BatchMoveZoneDialog } from '../ManageLabelView/Batch/molecules/BatchMoveZoneDialog';
import { BatchPatternDialog } from '../ManageLabelView/Batch/molecules/BatchPatternDialog';
import { BatchPingDialog } from '../ManageLabelView/Batch/molecules/BatchPingDialog';
import { BatchReadSensorDialog } from '../ManageLabelView/Batch/molecules/BatchReadSensorDialog';
import { BatchResetCounterDialog } from '../ManageLabelView/Batch/molecules/BatchResetCounterDialog';
import { BatchSwitchPageDialog } from '../ManageLabelView/Batch/molecules/BatchSwitchPageDialog';
import { BatchToggleIconDialog } from '../ManageLabelView/Batch/molecules/BatchToggleIconDialog';
export function LabelList(_a) {
    var labels = _a.labels, onClick = _a.onClick, page = _a.page, setPage = _a.setPage;
    var _b = React.useState([]), selected = _b[0], setSelected = _b[1];
    var _c = React.useState(undefined), show = _c[0], setShow = _c[1];
    var _d = React.useState(), lastClicked = _d[0], setLastClicked = _d[1];
    var token = useAuthentication().token;
    var onSetPage = function (page) {
        setPage(page);
    };
    var shiftSelect = function (label) {
        var start = labels.content.findIndex(function (l) { return l.barcode === lastClicked; });
        var end = labels.content.findIndex(function (l) { return l.barcode === label.barcode; });
        var selectedLabels = labels.content.slice(Math.min(start, end), Math.max(start, end) + 1);
        setSelected(selectedLabels.map(function (l) { return l.barcode; }));
    };
    var wrapOnClick = function (label, shift) {
        if (selected.length > 0) {
            if (shift && lastClicked) {
                shiftSelect(label);
            }
            else {
                if (selected.includes(label.barcode)) {
                    setSelected(function (s) { return s.filter(function (x) { return x !== label.barcode; }); });
                }
                else {
                    setSelected(function (s) { return __spreadArray(__spreadArray([], s, true), [label.barcode], false); });
                }
            }
        }
        else {
            onClick(label);
        }
        setLastClicked(label.barcode);
    };
    var wrapSelected = function (label, shift) {
        if (shift && lastClicked) {
            shiftSelect(label);
        }
        else {
            setSelected(function (s) { return __spreadArray(__spreadArray([], s.filter(function (x) { return x !== label.barcode; }), true), [
                label.barcode
            ], false); });
        }
        setLastClicked(label.barcode);
    };
    var selectedIds = selected.join(',');
    var query = selectedIds
        ? "access_token=".concat(token, "&ids=").concat(selectedIds)
        : "access_token=".concat(token);
    return (_jsxs(_Fragment, { children: [_jsx(BatchReadSensorDialog, { labels: selected, open: show === 'sensor', onClose: function () { return setShow(undefined); } }), _jsx(BatchFlashDialog, { labels: selected, open: show === 'flash', onClose: function () { return setShow(undefined); } }), _jsx(BatchFirmwareUpgradeDialog, { labels: selected, open: show === 'radio-firmware', onClose: function () { return setShow(undefined); } }), _jsx(BatchLocateDialog, { labels: selected, open: show === 'locate', onClose: function () { return setShow(undefined); } }), _jsx(BatchPingDialog, { labels: selected, open: show === 'ping', onClose: function () { return setShow(undefined); } }), _jsx(BatchPatternDialog, { labels: selected, open: show === 'pattern', onClose: function () { return setShow(undefined); } }), _jsx(BatchToggleIconDialog, { labels: selected, open: show === 'icon', onClose: function () { return setShow(undefined); } }), _jsx(BatchSwitchPageDialog, { labels: selected, open: show === 'switch-page', onClose: function () { return setShow(undefined); } }), _jsx(BatchResetCounterDialog, { labels: selected, open: show === 'reset-counter', onClose: function () { return setShow(undefined); } }), _jsx(BatchMoveZoneDialog, { labels: selected, open: show === 'move-zone', onClose: function () { return setShow(undefined); } }), _jsxs(Grid, { container: true, spacing: 2, width: '100%', children: [selected.length > 0 && (_jsx(Grid, { size: 12, children: _jsx(Alert, { severity: "success", action: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "text", onClick: function () {
                                            return setSelected(labels.content.map(function (b) { return b.barcode; }));
                                        }, children: "Select all" }), _jsx(Button, { variant: "text", onClick: function () { return setSelected([]); }, children: "Select none" }), _jsxs(PopupMenu, { children: [_jsx(PopupMenuItem, { onSelect: function () { return setShow('ping'); }, children: "Ping" }), _jsx(PopupMenuItem, { onSelect: function () { return setShow('locate'); }, children: "Locate" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onSelect: function () { return setShow('flash'); }, children: "Flash" }), _jsx(PopupMenuItem, { onSelect: function () { return setShow('pattern'); }, children: "Update pattern" }), _jsx(PopupMenuItem, { onSelect: function () { return setShow('sensor'); }, children: "Update sensor" }), _jsx(PopupMenuItem, { onSelect: function () { return setShow('icon'); }, children: "Toggle icon" }), _jsx(PopupMenuItem, { onSelect: function () { return setShow('switch-page'); }, children: "Switch page" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onSelect: function () { return setShow('radio-firmware'); }, children: "Upgrade Radio firmware" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onSelect: function () { return setShow('reset-counter'); }, children: "Reset counter" }), _jsx(PopupMenuItem, { onSelect: function () { return setShow('move-zone'); }, children: "Move to another zone" }), _jsx(PopupMenuItem, { onClick: function () {
                                                    return window.open("".concat(getConfig().apiBase, "/export/api/labels?").concat(query), '_blank');
                                                }, children: "Export to Excel" })] })] }), children: _jsx(Tooltip, { title: selected.join(', '), children: _jsxs(_Fragment, { children: [selected.length, " item(s) selected."] }) }) }) })), labels.content.map(function (c) { return (_jsx(LabelListItem, { label: c, onClick: wrapOnClick, hasSelection: selected.length > 0, selected: selected.includes(c.barcode), onSelect: wrapSelected }, c.barcode)); }), labels.content.length === 0 && _jsx(Grid, { children: "No labels found" })] }), _jsx(PricerPagination, { items: labels, page: page, setPage: onSetPage })] }));
}
