var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import React, { useEffect } from 'react';
export function DropDownMenu(_a) {
    var _b;
    var title = _a.title, value = _a.value, onChange = _a.onChange, menu = _a.menu;
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var anchorRef = React.useRef(null);
    var _d = React.useState(menu.findIndex(function (i) { return i.value == value; })), selectedIndex = _d[0], setSelectedIndex = _d[1];
    useEffect(function () {
        setSelectedIndex(menu.findIndex(function (i) { return i.value == value; }));
    }, [menu, value]);
    var handleClick = function () {
        onChange(menu[selectedIndex].value);
    };
    var handleMenuItemClick = function (index) {
        setSelectedIndex(index);
        setOpen(false);
        onChange(menu[index].value);
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var currentTitle = ((_b = menu[selectedIndex]) === null || _b === void 0 ? void 0 : _b.title) || '?';
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, { variant: "outlined", ref: anchorRef, "aria-label": title, children: [_jsx(Button, { onClick: handleClick, children: currentTitle }), _jsx(Button, { size: "small", "aria-controls": open ? 'button-menu' : undefined, "aria-expanded": open ? 'true' : undefined, "aria-label": title, "aria-haspopup": "menu", onClick: handleToggle, children: _jsx(ArrowDropDownIcon, {}) })] }), _jsx(Popper, { sx: {
                    zIndex: 1
                }, open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true, children: function (_a) {
                    var TransitionProps = _a.TransitionProps, placement = _a.placement;
                    return (_jsx(Grow, __assign({}, TransitionProps, { style: {
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }, children: _jsx(Paper, { children: _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(MenuList, { id: "button-menu", autoFocusItem: true, children: menu.map(function (option, index) { return (_jsxs(MenuItem, { selected: index === selectedIndex, onClick: function () { return handleMenuItemClick(index); }, children: [option.icon && (_jsx(ListItemIcon, { children: option.icon })), _jsx(ListItemText, { children: option.title })] }, "m_".concat(index))); }) }) }) }) })));
                } })] }));
}
