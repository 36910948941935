var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Button, Dialog, Divider, IconButton, Slide, Stack, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useIRBasestationContext } from '../../../context/IRBasestationContext';
import { ConfigureButton } from './ConfigureButton';
import { ConnectButton } from './ConnectButton';
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Slide, __assign({ direction: "up", ref: ref }, props));
});
export function IRBaseStationLog(_a) {
    var open = _a.open, onClose = _a.onClose;
    var _b = useIRBasestationContext(), log = _b.log, clearLog = _b.clearLog, isIrConnected = _b.isIrConnected;
    return (_jsxs(Dialog, { fullScreen: true, open: open, onClose: onClose, TransitionComponent: Transition, children: [_jsx(AppBar, { sx: { position: 'relative' }, children: _jsxs(Toolbar, { children: [_jsx(Typography, { sx: { ml: 2, flex: 1 }, variant: "h6", component: "div", children: "IR Log" }), _jsxs(Stack, { direction: "row", spacing: 2, children: [isIrConnected && _jsx(ConfigureButton, {}), isIrConnected && (_jsx(Divider, { orientation: "vertical", flexItem: true })), _jsx(ConnectButton, {}), _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsx(Button, { autoFocus: true, color: "inherit", onClick: clearLog, children: "Clear log" }), _jsx(IconButton, { edge: "start", color: "inherit", onClick: onClose, "aria-label": "close", children: _jsx(CloseIcon, {}) })] })] }) }), _jsx(Typography, { fontFamily: 'Roboto Mono', sx: { whiteSpace: 'pre-line' }, children: log.join('\n') })] }));
}
