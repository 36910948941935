var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TitleIcon from '@mui/icons-material/Title';
import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@mui/material';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useResetCounterMutation } from '../../../../api/labelApi';
import { ListItemInfo } from '../../../../components/atoms/ListItemInfo';
import { useMessageHandler } from '../../../../context/MessageContext';
export function CountersView(_a) {
    var _this = this;
    var _b, _c, _d;
    var label = _a.label;
    var addApiError = useMessageHandler().addApiError;
    var doResetCounter = useResetCounterMutation()[0];
    var _e = useLocalStorage('label.show.counters', false), expanded = _e[0], setExpanded = _e[1];
    var resetCounter = function (counter) { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, doResetCounter({
                            barcode: label.barcode,
                            counter: counter
                        }).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to ping label", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var errorCounter = ((_c = (_b = label.counters) === null || _b === void 0 ? void 0 : _b.find(function (c) { return c.key === 'error'; })) === null || _c === void 0 ? void 0 : _c.value) || 0;
    return (_jsx(_Fragment, { children: _jsxs(Accordion, { expanded: expanded, onChange: function (e, isExpanded) { return setExpanded(isExpanded); }, children: [_jsxs(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "details-content", id: "details-header", children: [_jsx(Typography, { sx: { width: '33%', flexShrink: 0 }, children: "Counters" }), _jsxs(Typography, { sx: {
                                display: { xs: 'none', sm: 'block' },
                                color: 'text.secondary'
                            }, children: ["Errors: ", errorCounter] })] }), _jsx(AccordionDetails, { children: _jsxs(List, { children: [_jsx(ListItemInfo, { title: "Reset all counters", icon: _jsx(TitleIcon, {}), onEdit: function () { return resetCounter('all'); }, editIcon: _jsx(ClearAllIcon, {}) }), (_d = label.counters) === null || _d === void 0 ? void 0 : _d.map(function (_a) {
                                var key = _a.key, value = _a.value, unit = _a.unit;
                                return (_jsx(ListItemInfo, { icon: _jsx(EqualizerIcon, {}), title: key, value: "".concat(value, " ").concat(unit), onEdit: function () { return resetCounter(key); }, editIcon: _jsx(ClearIcon, {}) }, key));
                            })] }) })] }) }));
}
