var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { transmissionApi } from '../../../../../api/api';
import { itemApi } from '../../../../../api/itemApi';
import { labelApi, usePingLabelMutation } from '../../../../../api/labelApi';
import { buildPageFilterQuery } from '../../../../../common/helpers';
import { useQuery } from '../../../../../common/hooks/query';
import { IRTargetList } from '../../../../../components/atoms/IRTargetList';
import { BackButton } from '../../../../../components/atoms/toolbar/BackButton';
import { PopupMenu } from '../../../../../components/atoms/toolbar/PopupMenu';
import { PopupMenuItem } from '../../../../../components/atoms/toolbar/PopupMenuItem';
import { RefreshButton } from '../../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../../components/atoms/toolbar/Spacing';
import { useMessageHandler } from '../../../../../context/MessageContext';
import { usePendingContext } from '../../../../../context/PendingContext';
import { useAppDispatch } from '../../../../../store/store';
export function LabelToolBar(_a) {
    var _this = this;
    var label = _a.label, isFetching = _a.isFetching, target = _a.target, setTarget = _a.setTarget, setShowSetup = _a.setShowSetup, setShowUpgrade = _a.setShowUpgrade, setShowIRUpgrade = _a.setShowIRUpgrade, setShowDelete = _a.setShowDelete;
    var query = useQuery();
    var addApiError = useMessageHandler().addApiError;
    var navigator = useNavigate();
    var add = usePendingContext().add;
    var dispatch = useAppDispatch();
    var pingLabel = usePingLabelMutation()[0];
    var page = parseInt(query.get('page') || '0');
    var filter = query.get('filter') || '';
    var refreshApi = function () {
        dispatch(labelApi.util.invalidateTags(['Label']));
        dispatch(transmissionApi.util.invalidateTags(['IRTargets', 'Messages']));
        dispatch(itemApi.util.invalidateTags(['Links']));
    };
    var onRaw = function () {
        if (!label) {
            navigator("/labels");
        }
        else {
            navigator("/labels/".concat(label.barcode, "/raw"));
        }
    };
    var onUdd = function () {
        if (!label) {
            navigator("/labels");
        }
        else {
            navigator("/labels/".concat(label.barcode, "/udd"));
        }
    };
    var ping = function (destination) { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!label) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, pingLabel({
                            barcode: label.barcode,
                            target: target,
                            destination: destination
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'PING', label.barcode);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to ping label", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onShowLogs = function () {
        if (!label) {
            navigator("/labels");
        }
        else {
            navigator("/messages?filter=barcode:".concat(label.barcode));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/labels".concat(buildPageFilterQuery(page, filter)) }), _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsx(Typography, { children: label === null || label === void 0 ? void 0 : label.barcode }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching }), label && (_jsxs(_Fragment, { children: [_jsx(IRTargetList, { label: label, title: "Current Target", accessPoint: target, setAccessPoint: setTarget }), _jsxs(PopupMenu, { children: [_jsx(PopupMenuItem, { onClick: onShowLogs, children: "Show logs" }), _jsx(PopupMenuItem, { onClick: onRaw, children: "Send raw frames" }), _jsx(PopupMenuItem, { onClick: onUdd, children: "Configure UDD" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onClick: function () { return ping('label'); }, children: "Ping Label" }), _jsx(PopupMenuItem, { onClick: function () { return ping('asic'); }, children: "Ping Asic (ir)" }), _jsx(PopupMenuItem, { onClick: function () { return ping('retrieve-info'); }, children: "Fetch info (radio)" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onClick: function () { return setShowSetup(true); }, children: "Setup label" }), _jsx(PopupMenuItem, { onClick: function () { return setShowIRUpgrade(true); }, children: "Upgrade ir firmware" }), _jsx(PopupMenuItem, { onClick: function () { return setShowUpgrade(true); }, children: "Upgrade radio firmware" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onClick: function () { return setShowDelete(true); }, children: "Delete label" })] })] }))] }));
}
