var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './helpers';
export var accessPointApi = createApi({
    reducerPath: 'accessPointApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: ['AccessPoint', 'AccessPoints', 'Untrusted', 'Untrusteds'],
    endpoints: function (builder) { return ({
        listAccessPoints: builder.query({
            query: function (_a) {
                var page = _a.page, _b = _a.size, size = _b === void 0 ? 10 : _b, _c = _a.zoneId, zoneId = _c === void 0 ? '' : _c;
                return ({
                    url: "transmission/api/v1/access-points?page=".concat(page, "&size=").concat(size, "&zoneId=").concat(zoneId),
                    responseHandler: responseHandler
                });
            },
            providesTags: ['AccessPoints'],
            transformResponse: (transformPaginatedResponse)
        }),
        saveAccessPoint: builder.mutation({
            query: function (ap) { return ({
                url: "transmission/api/v1/access-points/".concat(ap.id),
                responseHandler: responseHandler,
                method: 'PUT',
                body: __assign(__assign({}, ap), { id: undefined })
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: 'AccessPoint', id: id },
                    { type: 'AccessPoints' }
                ];
            }
        }),
        runAccessPoint: builder.mutation({
            query: function (ap) { return ({
                url: "transmission/api/v1/access-points/".concat(ap.id, "/commands"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, ap), { id: undefined })
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: 'AccessPoint', id: id },
                    { type: 'AccessPoints' }
                ];
            }
        }),
        getAccessPoint: builder.query({
            query: function (id) { return ({
                url: "transmission/api/v1/access-points/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'AccessPoint', id: id }]; }
        }),
        deleteAccessPoint: builder.mutation({
            query: function (id) { return ({
                url: "transmission/api/v1/access-points/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function () { return [{ type: 'AccessPoints' }]; }
        }),
        listUntrusted: builder.query({
            query: function () { return ({
                url: "transmission/api/v1/untrusted-access-points",
                responseHandler: responseHandler
            }); },
            providesTags: ['Untrusteds']
        }),
        saveUntrusted: builder.mutation({
            query: function (ap) { return ({
                url: "transmission/api/v1/untrusted-access-points/".concat(ap.hardwareId),
                responseHandler: responseHandler,
                method: 'PUT',
                body: __assign(__assign({}, ap), { id: undefined })
            }); },
            invalidatesTags: function (result, error, _a) {
                var hardwareId = _a.hardwareId;
                return [
                    { type: 'Untrusted', hardwareId: hardwareId },
                    { type: 'Untrusteds' }
                ];
            }
        }),
        getUntrusted: builder.query({
            query: function (id) { return ({
                url: "transmission/api/v1/untrusted-access-points/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Untrusted', id: id }]; }
        }),
        deleteUntrusted: builder.mutation({
            query: function (hardwareId) { return ({
                url: "transmission/api/v1/untrusted-access-points/".concat(hardwareId),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function () { return [{ type: 'Untrusteds' }]; }
        })
    }); }
});
export var useListAccessPointsQuery = accessPointApi.useListAccessPointsQuery, useGetAccessPointQuery = accessPointApi.useGetAccessPointQuery, useSaveAccessPointMutation = accessPointApi.useSaveAccessPointMutation, useRunAccessPointMutation = accessPointApi.useRunAccessPointMutation, useDeleteAccessPointMutation = accessPointApi.useDeleteAccessPointMutation, useGetUntrustedQuery = accessPointApi.useGetUntrustedQuery, useListUntrustedQuery = accessPointApi.useListUntrustedQuery, useSaveUntrustedMutation = accessPointApi.useSaveUntrustedMutation, useDeleteUntrustedMutation = accessPointApi.useDeleteUntrustedMutation;
