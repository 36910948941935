var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, TextField } from '@mui/material';
import React from 'react';
import { FormArea } from '../../../../../components/atoms/form/FormArea';
export function AddVersionStep(_a) {
    var product = _a.product, firmware = _a.firmware, setFirmware = _a.setFirmware;
    return (_jsxs(FormArea, { children: [_jsx(TextField, { label: "Product", variant: "outlined", disabled: true, value: product }), _jsxs(Stack, { direction: 'row', spacing: 3, children: [_jsx(TextField, { label: "Major version", variant: "outlined", type: "number", value: firmware.majorVersion, onChange: function (e) {
                            return setFirmware(__assign(__assign({}, firmware), { majorVersion: +e.target.value }));
                        }, autoFocus: true }), _jsx(TextField, { label: "Minor version", variant: "outlined", type: "number", value: firmware.minorVersion, onChange: function (e) {
                            return setFirmware(__assign(__assign({}, firmware), { minorVersion: +e.target.value }));
                        } }), _jsx(TextField, { label: "Git SHA", variant: "outlined", value: firmware.gitSha, onChange: function (e) {
                            return setFirmware(__assign(__assign({}, firmware), { gitSha: e.target.value }));
                        } })] }), _jsx(TextField, { label: "Change log", variant: "outlined", value: firmware.changeLog, multiline: true, rows: 4, onChange: function (e) {
                    return setFirmware(__assign(__assign({}, firmware), { changeLog: e.target.value }));
                } })] }));
}
